import React, { useContext } from "react";
import { AuthContext } from '../../contexts/AuthContext'
import { Col, Row, Button } from "reactstrap";
import Footer from "../../layout/Footer/Footer";
import Header from "../../layout/Header/Header";

function BotonesMateriales() {
  const { user } = useContext(AuthContext);
  return (
    <>
          <Header />
          {user.menu_articulos ?(
      <div className="container">
        <br />
        <br />
        <h3>Telas</h3>
        
        <Row >
        <Col md={4} align="center">
          {user.menu_colores ?(
            <Button href="/Colores" className="botonesMenu" color="success">
              <i class="fas fa-palette fa-7x"></i>
              <br />
              <br />
              Colores
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-palette fa-7x"></i>
          <br />
          <br />
          Colores
        </Button> }
          </Col>
         <Col md={4} align="center">
          {user.menu_tipos_telas ?(
            <Button href="/TiposTelas" className="botonesMenu" color="success">
              <i class="fab fa-staylinked fa-7x"></i>
              <br />
              <br />
              Tipo de Tela
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fab fa-staylinked fa-7x"></i>
          <br />
          <br />
          Tipo de Tela
        </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_telas ?(
            <Button href="/ListadoTelas" className="botonesMenu" color="success">
              <i class="fas fa-tape fa-7x"></i>
              <br />
              <br />
              Telas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tape fa-7x"></i>
            <br />
            <br />
            Telas
          </Button> }
          </Col>
          </Row>
          <br />
          <Row>
          <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoInventariosTelas" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario Telas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario Telas
        </Button> }
          </Col>
          <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoInventariosTelasPicos" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario 
              <br />Telas Picos
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario 
              <br />Telas Picos
        </Button> }
          </Col>
          {/* <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoInventariosTelasStock" className="botonesMenu" color="success">
              <i class="fas fa-warehouse fa-7x"></i>
              <br />
              <br />
              Inventario 
              <br />Telas Stock
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-warehouse fa-7x"></i>
          <br />
          <br />
          Inventario 
              <br />Telas Stock
        </Button> }
          </Col> */}
          <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoSeguimientoTelas" className="botonesMenu" color="success">
              <i class="fas fa-search fa-7x"></i>
              <br />
              <br />
              Seguimiento Telas
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-search fa-7x"></i>
          <br />
          <br />
          Seguimiento Telas
        </Button> }
          </Col>
          </Row>
          <br />
          <Row>
              <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoSalidaTelas" className="botonesMenu" color="success">
              <i class="fas fa-sign-out-alt fa-7x"></i>
              <br />
              <br />
              Salida Tela
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-sign-out-alt fa-7x"></i>
          <br />
          <br />
          Salida Tela
        </Button> }
          </Col>
          </Row>
          <br />
        <br />


        
        <h3>Habilitacion</h3>
          <Row>
          <Col md={4} align="center">
            {user.menu_habilitacion ?(
            <Button href="/ListadoHabilitacion" className="botonesMenu" color="success">
              <i class="fas fa-tag fa-7x"></i>
              <br />
              <br />
              Habilitacion
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tag fa-7x"></i>
            <br />
            <br />
            Habilitacion
          </Button> }
          </Col>


          <Col md={4} align="center">
          {user.menu_habilitacion ?(
            <Button href="/ListadoInventariosHabilitacion" className="botonesMenu" color="success">
              <i class="fas fa-tags fa-7x"></i>
              <br />
              <br />
              Inventario Habilitacion
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tags fa-7x"></i>
          <br />
          <br />
          Inventario Habilitacion
        </Button> }
          </Col>
          {/* <Col md={4} align="center">
          {user.menu_telas ?(
            <Button href="/ListadoSalidaHabilitacion" className="botonesMenu" color="success">
              <i class="fas fa-tags fa-7x"></i>
              <br />
              <br />
              Salida Habilitacion
            </Button>
          ): <Button disabled className="botonesMenu" color="success">
          <i class="fas fa-tags fa-7x"></i>
          <br />
          <br />
          Salida Habilitacion
        </Button> }
          </Col> */}
          
          </Row>
        <br />
        <br />


        
        <h3>Articulos</h3>
        
        <Row >
      
          {/* <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoArticulosPadre" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              Articulos Padre
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Articulos Padre
          </Button> }
          </Col> */}
          {/* <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Dibujos" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Dibujos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Dibujos
          </Button> }
            </Col> */}
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Marcas" className="botonesMenu" color="success">
              <i class="far fa-copyright fa-7x"></i>
              <br />
              <br />
              Marcas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="far fa-copyright fa-7x"></i>
            <br />
            <br />
            Marcas
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/Colecciones" className="botonesMenu" color="success">
              <i class="fas fa-calendar-alt fa-7x"></i>
              <br />
              <br />
              Colecciones
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-calendar-alt fa-7x"></i>
            <br />
            <br />
            Colecciones
          </Button> }
            </Col>
         <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoDepartamentos" className="botonesMenu" color="success">
              <i class="fas fa-list-ul fa-7x"></i>
              <br />
              <br />
              Departamentos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-list-ul fa-7x"></i>
            <br />
            <br />
            Departamentos
          </Button> }
          </Col>
          </Row>
          <br />
         <Row>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoSubDepartamentos" className="botonesMenu" color="success">
              <i class="fas fa-list-ul fa-7x"></i>
              <br />
              <br />
              Sub Departamentos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-list-ul fa-7x"></i>
            <br />
            <br />
            Sub Departamentos
          </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoClases" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Clases
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Clases
          </Button> }
          </Col>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoSubClases" className="botonesMenu" color="success">
              <i class="fas fa-bars fa-7x"></i>
              <br />
              <br />
              Etiquetas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-bars fa-7x"></i>
            <br />
            <br />
            Etiquetas
          </Button> }
          </Col>
          </Row>
        <br />
          <Row>
          <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoTallas" className="botonesMenu" color="success">
              <i class="fas fa-ruler-horizontal fa-7x"></i>
              <br />
              <br />
              Tallas
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-ruler-horizontal fa-7x"></i>
            <br />
            <br />
            Tallas
          </Button> }
            </Col>
            <Col md={4} align="center">
            {user.menu_articulos ?(
            <Button href="/ListadoArticulos" className="botonesMenu" color="success">
              <i class="fas fa-tshirt fa-7x"></i>
              <br />
              <br />
              Articulos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tshirt fa-7x"></i>
            <br />
            <br />
            Articulos
          </Button> }
          </Col>
         </Row>


         <br />
        <br />
         <h3>Clientes</h3>
        
        <Row>
        <Col md={4} align="center">
              {user.menu_clientes ? (
                <Button
                  href="/Zonas"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-map-marked-alt fa-7x"></i>
                  <br />
                  <br />
                  Zonas
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-map-marked-alt fa-7x"></i>
                  <br />
                  <br />
                  Zonas
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.menu_clientes ? (
                <Button
                  href="/Clientes"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Clientes
                </Button>
              )}
            </Col>
            <Col md={4} align="center">
              {user.menu_clientes ? (
                <Button
                  href="/ListadoCompradores"
                  className="botonesMenu"
                  color="success"
                >
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Compradores
                </Button>
              ) : (
                <Button disabled className="botonesMenu" color="success">
                  <i class="fas fa-user-tie fa-7x"></i>
                  <br />
                  <br />
                  Compradores
                </Button>
              )}
            </Col>
        </Row>
         


        <br />
        <br />
         <h3>Proveedores</h3>
        
        <Col md={4} align="center">
            {user.menu_proveedores ?(
            <Button href="/Proveedores" className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Proveedores
            </Button> }
          </Col>

          <br />
        <br />
         <h3>Produccion</h3>
        
<Row>
        <Col md={4} align="center">
            {user.menu_proveedores ?(
            <Button href="/Maquileros" className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Maquileros
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-dolly fa-7x"></i>
              <br />
              <br />
              Maquileros
            </Button> }
          </Col>

          <Col md={4} align="center">
            {user.menu_produccion ?(
            <Button href="/CatalogoProcesos" className="botonesMenu" color="success">
            <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Catalogo Procesos
            </Button>
            ): <Button disabled className="botonesMenu" color="success">
            <i class="fas fa-tasks fa-7x"></i>
              <br />
              <br />
              Catalogo Procesos
            </Button> }
          </Col>
          </Row>
      </div>
      ): undefined } 
      
    </>
  );
}

export default BotonesMateriales;
