import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import StatusMultiple from "../../StatusMultiple";
import Autorizado from "../../Autorizado";
import { TableHeader, Pagination, Search } from "../../../DataTable"
import useFullPageLoader from "../../../hooks/useFullPageLoader"
import moment from 'moment';

import { v4 as uuidv4 } from "uuid";

function ListadoOrdenesCompra() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_TIPOS_TELAS = process.env.REACT_APP_URL_TIPOS_TELAS;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_ORDENES_COMPRA = process.env.REACT_APP_URL_ORDENES_COMPRA;
  const URL_TELAS_OC = process.env.REACT_APP_URL_TELAS_OC;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  // const URL_CLIENTES = process.env.REACT_APP_URL_CLIENTES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date()
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-60);

  let validaInter = new Date()
  validaInter.setDate(validaInter.getDate()+15);

  let validaNac = new Date()
  validaNac.setDate(validaNac.getDate()+8);
 
  let endDate   = moment(hoy).format("YYYY-MM-DD"); 
  let startDate   = moment(inicio).format("YYYY-MM-DD");
  let validadorInter   = moment(validaInter).format("YYYY-MM-DD");
  let validadorNac   = moment(validaNac).format("YYYY-MM-DD");

  const [ordenesCompra, setOrdenesCompra] = useState([]);

  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);
  const [artOC, setArtOC] = useState([]);
  const [selectedTiposTelasFiltro, setSelectedTiposTelasFiltro] = useState("");
  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  // const [clientes, setClientes] = useState([]);
  // const [selectedCliente, setSelectedCliente] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);
  const [selectedFechaCompInicio, setSelectedFechaCompInicio] = useState("");
  const [selectedFechaCompFin, setSelectedFechaCompFin] = useState("");
  const [selectedTipo, setSelectedTipo] = useState("");
  const [empresa, setEmpresa] = useState("");

  const [fecha, setFecha] = useState("");
  const [plazo, setPlazo] = useState("");
  const [fecha_compromiso, setFechaCompromiso] = useState("");
  const [impuestos, setImpuestos] = useState("");
  // const [fecha_cita, setFechaCita] = useState("");
  // const [fecha_cancelacion_pedido, setFechaCancelacionPedido] = useState("");
  // const [fecha_creacion_prepedido, setFechaCreacionPrePedido] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [total_general, setTotalGeneral] = useState("");
  const [total_kilos, setTotalUnidaKilos] = useState("");
  const [total_metros, setTotalUnidaMetros] = useState("");
  const [total_piezas, setTotalUnidaPiezas] = useState("");
  const [numero_orden, setNumeroOrden] = useState("");
  const [tipo, setTipo] = useState("");

  const [idEdit, setIdEdit] = useState("");
  const [selectedProveedorEdit, setSelectedProveedorEdit] = useState("");
  // const [selectedClienteEdit, setSelectedClienteEdit] = useState("");
  // const [selectedPendFechaConf, setSelectedPendFechaConf] = useState("");

  const [fechaEdit, setFechaEdit] = useState("");
  const [telasOrden, setTelasOrden] = useState([]);
  const [habilitacionOrden, setHabilitacionOrden] = useState([]);
  const [modalEditTelas, setModalEditTelas] = useState(false);
  const toggleEditTelas = () => setModalEditTelas(!modalEditTelas);
  const [modalEditHabilitacion, setModalEditHabilitacion] = useState(false);
  const toggleEditHabilitacion = () => setModalEditHabilitacion(!modalEditHabilitacion);
  const [total_general_edit, setTotalGeneralEdit] = useState(0);
  const [total_kilos_edit, setTotalKilosEdit] = useState(0);
  const [total_metros_edit, setTotalMetrosEdit] = useState(0);
  const [total_piezas_edit, setTotalPiezasEdit] = useState(0);
  const [totalIVA, setTotalIVA] = useState(0);
  const [IVA, setIVA] = useState(0);


  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      telas: "",
      unidad: 0,
      colores: "",
      // arrayColoresTela:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      observaciones: "NA"
    },
  ]);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      habilitacion: "",
      unidad: 0,
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      observaciones: "NA"
    },
  ]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [status, setStatus] = useState('Abierto');
  const [autorizado, setAutorizado] = useState('');

    //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50

  useEffect(() => {
    axios
      .get(URL_ORDENES_COMPRA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allOrdenes = response.data;
    // Array para pagination
    let arrayTabla = allOrdenes
    .sort((a, b) => (a.idOrdenCompra < b.idOrdenCompra ? 1 : -1))
    .map((a) => {
      return {
        _id:a._id,
        numero: a.idOrdenCompra,
        activo: a.is_active,
        fecha: a.fecha,
        plazo: a.plazo,
        fecha_compromiso: a.fecha_compromiso,
        // fecha_cita: a.fecha_cita,
        // fecha_cancelacion_pedido: a.fecha_cancelacion_pedido,
        // fecha_creacion_prepedido: a.fecha_creacion_prepedido,
        observaciones: a.observaciones,
        total_general: a.total_general,
        proveedores: a.proveedores[0].nombre_comercial,
        idProveedores: a.proveedores[0]._id,
        nacInt: a.proveedores[0].nacInt,
        // clientes: a.clientes[0].nombre_comercial,
        // idClientes: a.clientes[0]._id,
        total_kilos: a.total_kilos,
        total_metros: a.total_metros,
        total_piezas: a.total_piezas,
        status: a.status,
        tipo:a.tipo,
        autorizado: a.autorizado,
        impuestos: a.impuestos,
        empresa: a.empresa,
        // pendFechaConf: a.pendFechaConf,
      };
    }).filter(function (el) {
      return el != null;
    });

    let dataFinal = Object.values(arrayTabla);

    setOrdenesCompra(allOrdenes);
    setComments(dataFinal)
    //

      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });

      // axios
      // .get(URL_CLIENTES, {
      //   headers: {
      //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //   },
      // })
      // .then((response) => {
      //   let allClientes = response.data;
      //   setClientes(allClientes);
      // })
      // .catch((err) => {
      //   console.log(err);
      // });

      axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTelas = response.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
        .get(URL_COLORES, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((res) => {
          const allColores = res.data;
          setColores(allColores);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allHabilitacion = response.data;
        setHabilitacion(allHabilitacion);
      })
      .catch((err) => {
        console.log(err);
      });

  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
          (status == 0 ||
            status == a.status) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedores)&&
              // (selectedCliente == 0 ||
              //   selectedCliente == a.idClientes)&&
              (selectedTipo == "" ||
                selectedTipo == a.tipo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.proveedores,
          a.tipo,
          // a.clientes,
          a.fecha_compromiso,
          // a.fecha_cita,
          // a.fecha_cancelacion_pedido,
          // a.fecha_creacion_prepedido,
          a.observaciones,
          a.total_kilos,
          a.total_metros,
          a.total_piezas,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Proveedor",
          "Tipo",
          // "Cliente",
          "Fecha Comp",
          // "Fecha Cita",
          // "Fecha Canc. Ped.",
          // "Fecha Crea. PrePed.",
          "Observaciones",
          "Kilos",
          "Metros",
          "Piezas",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Ordenes-Compra.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
          (status == 0 ||
            status == a.status) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedores)&&
              // (selectedCliente == 0 ||
              //   selectedCliente == a.idClientes)&&
              (selectedTipo == "" ||
                selectedTipo == a.tipo)
      ) {
        return {
          Numero:a.numero,
          FechaCreacion: a.fecha,
          Proveedor: a.proveedores,
          Tipo: a.tipo,
          // Cliente: a.clientes,
          FechaComp: a.fecha_compromiso,
          // FechaCita: a.fecha_cita,
          // FechaCancPed: a.fecha_cancelacion_pedido,
          // FechaCreaPrePed: a.fecha_creacion_prepedido,
          Observaciones: a.observaciones,
          Kilos: a.total_kilos,
          Metros: a.total_metros,
          Piezas:a.total_piezas,
          Total: a.total_general,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoOrdenesCompra";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoOrdenesCompra",
        sheetFilter: [
          "Numero",
          "FechaCreacion",
          "Proveedor",
          "Tipo",
          // "Cliente",
          "FechaComp",
          // "FechaCita",
          // "FechaCancPed",
          // "FechaCreaPrePed",
          "Observaciones",
          "Kilos",
          "Metros",
          "Piezas",
          "Total",
        ],
        sheetHeader: [
          "Numero",
          "FechaCreacion",
          "Proveedor",
          "Tipo",
          // "Cliente",
          "FechaComp",
          // "FechaCita",
          // "FechaCancPed",
          // "FechaCreaPrePed",
          "Observaciones",
          "Kilos",
          "Metros",
          "Piezas",
          "Total",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
          (status == 0 ||
            status == a.status) &&
            (selectedProveedor == 0 ||
              selectedProveedor == a.idProveedores)&&
              // (selectedCliente == 0 ||
              //   selectedCliente == a.idClientes)&&
              (selectedTipo == "" ||
                selectedTipo == a.tipo)
      ) {
        return [
          a.numero,
          a.fecha,
          a.proveedores,
          a.tipo,
          // a.clientes,
          a.fecha_compromiso,
          // a.fecha_cita,
          // a.fecha_cancelacion_pedido,
          // a.fecha_creacion_prepedido,
          a.observaciones,
          a.total_kilos,
          a.total_metros,
          a.total_piezas,
          "$" + new Intl.NumberFormat("en-US").format(a.total_general),
         ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Ordenes de Compra`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Numero",
          "Fecha",
          "Proveedor",
          "Tipo",
          // "Cliente",
          "Fecha Comp",
          // "Fecha Cita",
          // "Fecha Canc. Ped.",
          // "Fecha Crea. PrePed.",
          "Observaciones",
          "Kilos",
          "Metros",
          "Piezas",
          "Total",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Ordenes Compra",
          email: mailTo,
          fileName: "ListadoOrdenesCompra.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Ordenes de Compra.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(   
    fecha,
    plazo,
    fecha_compromiso,
    impuestos,
    // fecha_cita,
    // fecha_cancelacion_pedido,
    // fecha_creacion_prepedido,
    idProveedores,
    // idClientes,
    total_kilos,
    total_metros,
    total_piezas,
    total_general,
    observaciones,
    numero_orden,
    tipo,
    empresa,
    idEdit ) {
      setFecha(fecha);
      setPlazo(plazo)
      setFechaCompromiso(fecha_compromiso)
      setImpuestos(impuestos)
      // setFechaCita(fecha_cita)
      // setFechaCancelacionPedido(fecha_cancelacion_pedido)
      // setFechaCreacionPrePedido(fecha_creacion_prepedido)
      setSelectedProveedorEdit(idProveedores)
      // setSelectedClienteEdit(idClientes)
      setTotalUnidaKilos(total_kilos)
      setTotalUnidaMetros(total_metros)
      setTotalUnidaPiezas(total_piezas)
      setTotalGeneral(total_general)
      setObservaciones(observaciones)
      setNumeroOrden(numero_orden)
      setEmpresa(empresa)
      setIdEdit(idEdit);
      toggleEdit();
  }

  let IVAPDF = 0
  let totalPDF = 0
 async function PDFOC(   
  fecha,
  plazo,
  fecha_compromiso,
  // fecha_cita,
  idProveedores,
  proveedores,
  // clientes,
  total_kilos,
  total_metros,
  total_piezas,
  total_general,
  observaciones,
  numero,
  tipo,
  impuestos,
  empresa,
  idPDFOC ) {
    if(impuestos== "Si"){
      IVAPDF =total_general * .16
      totalPDF = total_general * 1.16
    }else{
      IVAPDF = 0
      totalPDF = total_general
    }
    
     await axios
      .get(`${URL_TELAS_OC}/ordenesCompra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTelasOrden = response.data
        if(tipo =="Tela"){
        let arrayTabla = allTelasOrden
        .map((a) => {
          if(a.cantidad > 0){
          return {
            id:a._id,
            articulo: a.telas[0].codigo + " " + a.telas[0].nombre,
            descripcion: a.telas[0].tiposTelas[0].name,
            unidad: a.telas[0].unidad,
            colores: a.colores[0].name,
            cantidad: a.cantidad,
            precio: a.precio,
            descuento: a.descuento,
            total: a.total,
            pendiente_recibir: a.pendiente_recibir,
            observaciones: a.observaciones
          }
        }
        }).filter(function (el) {
          return el != null;
        })
        var dataFinal = Object.values(arrayTabla);
      } else{
        let arrayTabla = allTelasOrden
        .map((a) => {
          if(a.cantidad > 0){
          return {
            id:a._id,
            articulo: a.habilitacion[0].nombre  + " " + a.habilitacion[0].descripcion,
            descripcion: a.habilitacion[0].descripcion,
            unidad: a.habilitacion[0].unidad,
            colores: "NA",
            cantidad: a.cantidad,
            precio: a.precio,
            descuento: a.descuento,
            total: a.total,
            pendiente_recibir: a.pendiente_recibir,
            observaciones: a.observaciones
          }
        }
        }).filter(function (el) {
          return el != null;
        });
        var dataFinal = Object.values(arrayTabla); 
        }
    
         

        let mts = dataFinal.filter(e=>e.unidad == "Metros")
        let totalMts = mts.map((c) => parseFloat(c.cantidad));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalMetrosEdit(TM);
        let KG = dataFinal.filter(e=>e.unidad == "Kilos")
        let totalKG = KG.map((c) => parseFloat(c.cantidad));
        let TK = totalKG.reduce((t, total, index) => t + total, 0);
        setTotalKilosEdit(TK);
        let PZ = dataFinal.filter(e=>e.unidad == "Piezas")
        let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
        let TP = totalPZ.reduce((t, total, index) => t + total, 0);
        setTotalPiezasEdit(TP);
        let importes = dataFinal.map((c) => parseFloat(c.total));
        let TG = importes.reduce((t, total, index) => t + total, 0);
        setTotalGeneralEdit(TG);


        if(tipo == "Tela"){
        const data = dataFinal.map((a) => {
            return [
              a.articulo,
              a.descripcion,
              a.observaciones,
              a.unidad,
              a.colores,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              a.descuento + " %",
              "$" + new Intl.NumberFormat("en-US").format(a.total),
             ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Orden de Compra # ${numero}`, 20, 20);
        doc.setFontSize(12)
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        // doc.text(`Cliente ${clientes}`, 20, 35);
        doc.text(`Plazo ${plazo} días Compromiso ${fecha_compromiso}`, 20, 35);
        doc.text(`Metros ${new Intl.NumberFormat("en-US").format(total_metros)} / Kilos ${new Intl.NumberFormat("en-US").format(total_kilos)} / Piezas ${new Intl.NumberFormat("en-US").format(total_piezas)}`, 20, 40)
        doc.text(`Observaciones ${observaciones}`, 20, 45);
        doc.autoTable({
          head: [
            [
              "Articulo",
              "Descripcion",
              "Observaciones",
              "Unidad",
              "Color",
              "Cantidad",
              "Precio",
              "Descuento",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          startY: 50,
          foot: [
            [
              "",
              "",
              "",
              "",
              "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(total_general),
            ],
          ],
          showFoot: "lastPage",
        });
        // doc.save(`OC-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
    }else{
        const data = dataFinal.map((a) => {
            return [
              a.articulo,
              // a.descripcion,
              a.observaciones,
              a.unidad,
              // a.colores,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              a.descuento + " %",
              "$" + new Intl.NumberFormat("en-US").format(a.total),
             ]
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        // var img2 = new Image();
        // img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        // doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.setFontSize(14)
        doc.text(`Empresa ${empresa}`, 20, 15);
        doc.text(`Orden de Compra # ${numero}`, 20, 20);
        doc.setFontSize(12)
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Proveedor ${proveedores}`, 20, 30);
        // doc.text(`Cliente ${clientes}`, 20, 35);
        doc.text(`Plazo ${plazo} días Compromiso ${fecha_compromiso}`, 20, 35);
        doc.text(`Metros ${new Intl.NumberFormat("en-US").format(total_metros)} / Kilos ${new Intl.NumberFormat("en-US").format(total_kilos)} / Piezas ${new Intl.NumberFormat("en-US").format(total_piezas)}`, 20, 40)
        doc.text(`Observaciones ${observaciones}`, 20, 45);
        doc.autoTable({
          head: [
            [
              "Articulo",
              // "Descripcion",
              "Color",
              "Unidad",
              // "Color",
              "Cantidad",
              "Precio",
              "Descuento",
              "Total",
            ],
          ],
          body: dataPDFLimpia,
          startY: 50,
          foot: [
            [
              "",
              // "",
              "",
              "",
              "",
              // "",
              "",
              "SubTotal",
              "$" + new Intl.NumberFormat("en-US").format(total_general),
            ],
            [
              "",
              "",
              "",
              // "",
              // "",
              "",
              "",
              "IVA",
              "$" + new Intl.NumberFormat("en-US").format(IVAPDF),
            ],
            [
              "",
              "",
              "",
              // "",
              // "",
              "",
              "",
              "Total",
              "$" + new Intl.NumberFormat("en-US").format(totalPDF),
            ],
          ],
          showFoot: "lastPage",
        });

        var lines = doc.splitTextToSize(`Nombre y Firma Proveedor`, 200);
        var lines2 = doc.splitTextToSize(`___________________`, 200);
        doc.text(20, doc.autoTable.previous.finalY + 30, lines);
        doc.text(23, doc.autoTable.previous.finalY + 23, lines2);

            var lines3 = doc.splitTextToSize(`Firma de Comprador`, 200);
            var lines4 = doc.splitTextToSize(`___________________`, 200);
            doc.text(130, doc.autoTable.previous.finalY + 30, lines3);
            doc.text(125, doc.autoTable.previous.finalY + 23, lines4);


            var lines5 = doc.splitTextToSize(`Firma Recepción`, 200);
            var lines6 = doc.splitTextToSize(`___________________`, 200);
            doc.text(230, doc.autoTable.previous.finalY + 30, lines5);
            doc.text(220, doc.autoTable.previous.finalY + 23, lines6);
        // doc.save(`OC-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
    }
      })
      .catch((err) => {
        console.log(err);
      });

     
  }


  function editOrdenes(event) {
    event.preventDefault();
    const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;

            axios.patch(
              URL_ORDENES_COMPRA_EDIT,
              {
                fecha,
                plazo,
                fecha_compromiso,
                impuestos,
                // fecha_cita,
                // fecha_cancelacion_pedido,
                // fecha_creacion_prepedido,
                proveedores: selectedProveedorEdit,
                // clientes: selectedClienteEdit,
                observaciones,
                empresa
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            )
          .then(() => {
            axios.post(
              URL_LOGS,
              {
                tipo: "Editar Orden deCompra",
                detalle: `${numero_orden} ${fecha}`,
                user: user.id,
              },
              {
                headers: {
                  Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
                },
              }
            );
           
            toggleEdit();
            Swal.fire("Good job!", "Actualizado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
  }


  const headers = [
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha Creacion", field: "fecha", sortable: false },
    { name: "Proveedor", field: "proveedores", sortable: false },
    { name: "NacInt", field: "nacInt", sortable: false },
    { name: "Tipo", field: "tipo", sortable: false },
    // { name: "Cliente", field: "clientes", sortable: false },
    // { name: "Pend Fecha Conf", field: "pendFechaConf", sortable: false },
    { name: "Compromiso", field: "fecha_compromiso", sortable: true },
    // { name: "Cita", field: "fecha_cita", sortable: true },
    // { name: "Cancelacion Ped", field: "fecha_cancelacion_pedido", sortable: true },
    // { name: "Creacion PrePedido", field: "fecha_creacion_prepedido", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Kilos", field: "total_kilos", sortable: true },
    { name: "Metros", field: "total_metros", sortable: true },
    { name: "Piezas", field: "total_piezas", sortable: true },
    { name: "Total", field: "total_general", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
    { name: "Editar", field: "editar", sortable: false },
];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          // comment.clientes.toLowerCase().includes(search.toLowerCase()) ||
          comment.nacInt.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          // comment.pendFechaConf.toLowerCase().includes(search.toLowerCase()) ||
          comment.total_kilos.toString().includes(search) ||
          comment.total_metros.toString().includes(search) ||
          comment.total_piezas.toString().includes(search) ||
          comment.total_general.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    // if(selectedCliente){
    //   computedComments = computedComments.filter((e)=>
    //   e.idClientes.includes(selectedCliente)
    //   )
    // }
    // if(selectedPendFechaConf){
    //   computedComments = computedComments.filter((e)=>
    //   e.pendFechaConf.includes(selectedPendFechaConf)
    //   )
    // }
    if (status) {
      computedComments = computedComments.filter((e) =>
        e.status.includes(status)
      );
    }
    if (autorizado) {
      computedComments = computedComments.filter((e) =>
        e.autorizado.includes(autorizado)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedFechaCompInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha_compromiso >= selectedFechaCompInicio
      );
    }

    if (selectedFechaCompFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha_compromiso <= selectedFechaCompFin
      );
    }
    if (selectedTipo) {
      computedComments = computedComments.filter((e) =>
        e.tipo.includes(selectedTipo)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total_kilos" &&
      sorting.field != "total_metros" &&
      sorting.field != "total_piezas" &&
      sorting.field != "total_general" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total_kilos" ||
        sorting.field == "total_metros" ||
        sorting.field == "total_piezas" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total_kilos" ||
        sorting.field == "total_metros" ||
        sorting.field == "total_piezas" ||
        sorting.field == "total_general" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    status,
    autorizado,
    selectedFechaInicio,
    selectedFechaFin,
    selectedProveedor,
    // selectedCliente,
    // selectedPendFechaConf,
    selectedTipo,
  ]);


function EditTelas(idOC, numero_orden, tipo, fecha){
  setTipo(tipo)
  setFechaEdit(fecha)
  setInputFields([
    {
      id: uuidv4(),
      telas: "",
      unidad: 0,
      colores: "",
      // arrayColoresTela:[],
      cantidad: 0,
      precio: 0,
      descuento: 0,
      total: 0,
      observaciones: "NA"
    },
  ])
  setIdEdit(idOC)
  setNumeroOrden(numero_orden)
  if(tipo=="Tela"){
  axios
  .get(`${URL_TELAS_OC}/ordenesCompra/${idOC}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allTelasOrden = response.data
    let arrayTabla = allTelasOrden
    .map((a) => {
      return {
        id:a._id,
        telas: a.telas[0]._id,
        unidad: a.telas[0].unidad,
        colores: a.colores[0]._id,
        // arrayColoresTela:a.telas[0].colores,
        cantidad: a.cantidad,
        precio: a.precio,
        descuento: a.descuento,
        total: a.total,
        pendiente_recibir: a.pendiente_recibir,
        observaciones: a.observaciones,
      };
    }).filter(function (el) {
      return el != null;
    });
    var dataFinal = Object.values(arrayTabla);  
    setTelasOrden(dataFinal)


    let mts = dataFinal.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);
    let KG = dataFinal.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);
    let PZ = dataFinal.filter(e=>e.unidad == "Piezas")
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezasEdit(TP);
    let importes = dataFinal.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  })
  .catch((err) => {
    console.log(err);
  });
  toggleEditTelas()} else{
    
  axios
  .get(`${URL_TELAS_OC}/ordenesCompra/${idOC}`, {
    headers: {
      Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    },
  })
  .then((response) => {
    let allTelasOrden = response.data
    let arrayTabla = allTelasOrden
    .map((a) => {
      return {
        id:a._id,
        habilitacion: a.habilitacion[0]._id,
        unidad: a.habilitacion[0].unidad,
        cantidad: a.cantidad,
        precio: a.precio,
        descuento: a.descuento,
        total: a.total,
        pendiente_recibir: a.pendiente_recibir,
        observaciones: a.observaciones
      };
    }).filter(function (el) {
      return el != null;
    });
    var dataFinal = Object.values(arrayTabla);  
    setHabilitacionOrden(dataFinal)

    let mts = dataFinal.filter(e=>e.unidad == "Metros")
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetrosEdit(TM);
    let KG = dataFinal.filter(e=>e.unidad == "Kilos")
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilosEdit(TK);

    let PZ = dataFinal.filter(e=>e.unidad == "Piezas")
    let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
    let TP = totalPZ.reduce((t, total, index) => t + total, 0);
    setTotalPiezasEdit(TP);
    let importes = dataFinal.map((c) => parseFloat(c.total));
    let TG = importes.reduce((t, total, index) => t + total, 0);
    setTotalGeneralEdit(TG);
  })
  .catch((err) => {
    console.log(err);
  });
  toggleEditHabilitacion()
  }
}

const handleChangeInputExistentes = (id, event) => {
  const newTelasOrden = telasOrden.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setTelasOrden(newTelasOrden);
  ActualizaTotales()
};

const handleChangeInputExistentesHab = (id, event) => {
  const newHabilitacionOrden = habilitacionOrden.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setHabilitacionOrden(newHabilitacionOrden);
  ActualizaTotalesHab()
};

const handleChangeInputExistentesCantidad = (id, event) => {
  const newTelasOrden = telasOrden.map((i) => {
    if (id === i.id) {
      let PR = parseFloat(i.pendiente_recibir) - parseFloat(i.cantidad) + parseFloat(event.target.value)
      i.pendiente_recibir = PR
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setTelasOrden(newTelasOrden);
  ActualizaTotales()
};

const handleChangeInputExistentesCantidadHab = (id, event) => {
  const newHabilitacionOrden = habilitacionOrden.map((i) => {
    if (id === i.id) {
      let PR = parseFloat(i.pendiente_recibir) - parseFloat(i.cantidad) + parseFloat(event.target.value)
      i.pendiente_recibir = PR
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setHabilitacionOrden(newHabilitacionOrden);
  ActualizaTotalesHab()
};


function BuscaTelaExistentes(id, event){
  telas.map((a)=>{
    if(a._id == event.target.value){
      let idTela = a._id
      let unidadTela = a.unidad
      let coloresTela = a.colores
      handleChangeInputExistentesTela(id, idTela, unidadTela, coloresTela)
    }
  })
}

const handleChangeInputExistentesTela = (id, idTela, unidadTela, coloresTela) => {
  const newTelasOrden = telasOrden.map((i) => {
    if (id === i.id) {
      i.telas = idTela;
      i.unidad = unidadTela
      // i.arrayColoresTela = coloresTela
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setTelasOrden(newTelasOrden);
  ActualizaTotales()
};

function BuscaHabilitacionExistentes(id, event){
  habilitacion.map((a)=>{
    if(a._id == event.target.value){
      let idHabilitacion = a._id
      let unidadHabilitacion = a.unidad
      handleChangeInputExistentesHabilitacion(id, idHabilitacion, unidadHabilitacion)
    }
  })
}

const handleChangeInputExistentesHabilitacion = (id, idHabilitacion, unidadHabilitacion) => {
  const newHabilitacionOrden = habilitacionOrden.map((i) => {
    if (id === i.id) {
      i.Habilitacions = idHabilitacion;
      i.unidad = unidadHabilitacion
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setHabilitacionOrden(newHabilitacionOrden);
  ActualizaTotalesHab()
};

const handleAddFields = () => {
  const ultimo = inputFields[inputFields.length-1];
  setInputFields([
    ...inputFields,
    {
      id: uuidv4(),
      telas: ultimo.telas,
      unidad: ultimo.unidad,
      colores: ultimo.colores,
      // arrayColoresTela: ultimo.arrayColoresTela,
      cantidad: 0,
      precio: ultimo.precio,
      descuento: ultimo.descuento,
      total: 0,
      observaciones: "NA"
    },
  ]);
};

const handleAddFieldsHab = () => {
  const ultimo = inputFieldsHab[inputFieldsHab.length-1];
  setInputFieldsHab([
    ...inputFieldsHab,
    {
      id: uuidv4(),
      habilitacion: ultimo.habilitacion,
      unidad: ultimo.unidad,
      cantidad: 0,
      precio: ultimo.precio,
      descuento: ultimo.descuento,
      total: 0,
      observaciones: "NA"
    },
  ]);
};

const handleRemoveFields = (id) => {
  const values = [...inputFields];
  values.splice(
    values.findIndex((value) => value.id === id),
    1
  );
  setInputFields(values);
  let mtsExistentes = telasOrden.filter(e=>e.unidad == "Metros")
  let mtsNuevos = values.filter(e=>e.unidad == "Metros")
  let mts = mtsExistentes.concat(mtsNuevos)
  let totalMts = mts.map((c) => parseFloat(c.cantidad));
  let TM = totalMts.reduce((t, total, index) => t + total, 0);
  setTotalMetrosEdit(TM);

  let KGExistentes = telasOrden.filter(e=>e.unidad == "Kilos")
  let KGNuevos = values.filter(e=>e.unidad == "Kilos")
  let KG = KGExistentes.concat(KGNuevos)
  let totalKG = KG.map((c) => parseFloat(c.cantidad));
  let TK = totalKG.reduce((t, total, index) => t + total, 0);
  setTotalKilosEdit(TK);

  let PZExistentes = telasOrden.filter(e=>e.unidad == "Piezas")
  let PZNuevos = values.filter(e=>e.unidad == "Piezas")
  let PZ = PZExistentes.concat(PZNuevos)
  let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
  let TP = totalPZ.reduce((t, total, index) => t + total, 0);
  setTotalPiezasEdit(TP);
  
  let importesExistentes = telasOrden.map((c) => parseFloat(c.total));
  let importesNuevos = values.map((c) => parseFloat(c.total));
  let importes = importesExistentes.concat(importesNuevos)
  let TG = importes.reduce((t, total, index) => t + total, 0);
  setTotalGeneralEdit(TG);

};

const handleRemoveFieldsHab = (id) => {
  const values = [...inputFieldsHab];
  values.splice(
    values.findIndex((value) => value.id === id),
    1
  );
  setInputFieldsHab(values);
  let mtsExistentes = habilitacionOrden.filter(e=>e.unidad == "Metros")
  let mtsNuevos = values.filter(e=>e.unidad == "Metros")
  let mts = mtsExistentes.concat(mtsNuevos)
  let totalMts = mts.map((c) => parseFloat(c.cantidad));
  let TM = totalMts.reduce((t, total, index) => t + total, 0);
  setTotalMetrosEdit(TM);

  let KGExistentes = habilitacionOrden.filter(e=>e.unidad == "Kilos")
  let KGNuevos = values.filter(e=>e.unidad == "Kilos")
  let KG = KGExistentes.concat(KGNuevos)
  let totalKG = KG.map((c) => parseFloat(c.cantidad));
  let TK = totalKG.reduce((t, total, index) => t + total, 0);
  setTotalKilosEdit(TK);

  let PZExistentes = habilitacionOrden.filter(e=>e.unidad == "Piezas")
  let PZNuevos = values.filter(e=>e.unidad == "Piezas")
  let PZ = PZExistentes.concat(PZNuevos)
  let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
  let TP = totalPZ.reduce((t, total, index) => t + total, 0);
  setTotalPiezasEdit(TP);
  
  let importesExistentes = habilitacionOrden.map((c) => parseFloat(c.total));
  let importesNuevos = values.map((c) => parseFloat(c.total));
  let importes = importesExistentes.concat(importesNuevos)
  let TG = importes.reduce((t, total, index) => t + total, 0);
  setTotalGeneralEdit(TG);

};

const handleChangeInput = (id, event) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales()
};

const handleChangeInputHab = (id, event) => {
  const newInputFieldsHab = inputFieldsHab.map((i) => {
    if (id === i.id) {
      i[event.target.name] = event.target.value;
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setInputFieldsHab(newInputFieldsHab);
  ActualizaTotalesHab()
};

function BuscaTela(id, event){
  telas.map((a)=>{
    if(a._id == event.target.value){
      let idTela = a._id
      let unidadTela = a.unidad
      let coloresTela = a.colores
      handleChangeInputTela(id, idTela, unidadTela, coloresTela)
    }
  })
}


const handleChangeInputTela = (id, idTela, unidadTela, coloresTela) => {
  const newInputFields = inputFields.map((i) => {
    if (id === i.id) {
      i.telas = idTela;
      i.unidad = unidadTela
      // i.arrayColoresTela = coloresTela
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setInputFields(newInputFields);
  ActualizaTotales()
};


function BuscaHabilitacion(id, event){
  habilitacion.map((a)=>{
    if(a._id == event.target.value){
      let idHabilitacion = a._id
      let unidadHabilitacion = a.unidad
      handleChangeInputHabilitacion(id, idHabilitacion, unidadHabilitacion)
    }
  })
}

const handleChangeInputHabilitacion = (id, idHabilitacion, unidadHabilitacion) => {
  const newInputFieldsHab = inputFieldsHab.map((i) => {
    if (id === i.id) {
      i.habilitacion = idHabilitacion;
      i.unidad = unidadHabilitacion
      let totalParcial = i.cantidad * i.precio;
      i.total = (totalParcial - (totalParcial * i.descuento) / 100).toFixed(2);
    }
    return i;
  });
  setInputFieldsHab(newInputFieldsHab);
  ActualizaTotalesHab()
};


function ActualizaTotales(){
      let mtsExistentes = telasOrden.filter(e=>e.unidad == "Metros")
      let mtsNuevos = inputFields.filter(e=>e.unidad == "Metros")
      let mts = mtsExistentes.concat(mtsNuevos)
      let totalMts = mts.map((c) => parseFloat(c.cantidad));
      let TM = totalMts.reduce((t, total, index) => t + total, 0);
      setTotalMetrosEdit(TM);

      let KGExistentes = telasOrden.filter(e=>e.unidad == "Kilos")
      let KGNuevos = inputFields.filter(e=>e.unidad == "Kilos")
      let KG = KGExistentes.concat(KGNuevos)
      let totalKG = KG.map((c) => parseFloat(c.cantidad));
      let TK = totalKG.reduce((t, total, index) => t + total, 0);
      setTotalKilosEdit(TK);

      let PZExistentes = telasOrden.filter(e=>e.unidad == "Piezas")
      let PZNuevos = inputFields.filter(e=>e.unidad == "Piezas")
      let PZ = PZExistentes.concat(PZNuevos)
      let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
      let TP = totalPZ.reduce((t, total, index) => t + total, 0);
      setTotalPiezasEdit(TP);
      
      let importesExistentes = telasOrden.map((c) => parseFloat(c.total));
      let importesNuevos = inputFields.map((c) => parseFloat(c.total));
      let importes = importesExistentes.concat(importesNuevos)
      let TG = importes.reduce((t, total, index) => t + total, 0);
      setTotalGeneralEdit(TG);
}

function ActualizaTotalesHab(){
  let mtsExistentes = habilitacionOrden.filter(e=>e.unidad == "Metros")
  let mtsNuevos = inputFieldsHab.filter(e=>e.unidad == "Metros")
  let mts = mtsExistentes.concat(mtsNuevos)
  let totalMts = mts.map((c) => parseFloat(c.cantidad));
  let TM = totalMts.reduce((t, total, index) => t + total, 0);
  setTotalMetrosEdit(TM);

  let KGExistentes = habilitacionOrden.filter(e=>e.unidad == "Kilos")
  let KGNuevos = inputFieldsHab.filter(e=>e.unidad == "Kilos")
  let KG = KGExistentes.concat(KGNuevos)
  let totalKG = KG.map((c) => parseFloat(c.cantidad));
  let TK = totalKG.reduce((t, total, index) => t + total, 0);
  setTotalKilosEdit(TK);

  let PZExistentes = habilitacionOrden.filter(e=>e.unidad == "Piezas")
  let PZNuevos = inputFieldsHab.filter(e=>e.unidad == "Piezas")
  let PZ = PZExistentes.concat(PZNuevos)
  let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
  let TP = totalPZ.reduce((t, total, index) => t + total, 0);
  setTotalPiezasEdit(TP);
  
  let importesExistentes = habilitacionOrden.map((c) => parseFloat(c.total));
  let importesNuevos = inputFieldsHab.map((c) => parseFloat(c.total));
  let importes = importesExistentes.concat(importesNuevos)
  let TG = importes.reduce((t, total, index) => t + total, 0);
  setTotalGeneralEdit(TG);
}



const saveOrdenCompra = async (event) => {
  event.preventDefault();
  
  const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;
  try {
    await axios
      .patch(
        URL_ORDENES_COMPRA_EDIT,
        {
          total_general:total_general_edit,
          total_kilos:total_kilos_edit,
          total_piezas:total_piezas_edit,
          total_metros:total_metros_edit
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        inputFields.map((a) => {
          axios.post(
            URL_TELAS_OC,
            {
              ordenesCompra: idEdit,
              telas: a.telas,
              colores: a.colores,
              cantidad: parseFloat(a.cantidad),
              recibido: 0,
              pendiente_recibir: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
              descuento: parseFloat(a.descuento),
              total: parseFloat(a.total),
              observaciones: a.observaciones,
              fecha: fechaEdit
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        telasOrden.map((a)=>{
          axios.patch(
            `${URL_TELAS_OC}/${a.id}`,
            {
              telas: a.telas,
              colores: a.colores,
              cantidad: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
              descuento: parseFloat(a.descuento),
              total: parseFloat(a.total),
              pendiente_recibir: parseFloat(a.pendiente_recibir),
              observaciones: a.observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Orden de Compra",
            detalle: `${numero_orden}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `${error.response.data}`,
    });
    console.log(error);
  }
};

const saveOrdenCompraHab = async (event) => {
  event.preventDefault();
  const URL_ORDENES_COMPRA_EDIT = `${process.env.REACT_APP_URL_ORDENES_COMPRA}/${idEdit}`;
  try {
    await axios
      .patch(
        URL_ORDENES_COMPRA_EDIT,
        {
          total_general:total_general_edit,
          total_kilos:total_kilos_edit,
          total_piezas:total_piezas_edit,
          total_metros:total_metros_edit
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        inputFieldsHab.map((a) => {
          axios.post(
            URL_TELAS_OC,
            {
              ordenesCompra: idEdit,
              habilitacion: a.habilitacion,
              cantidad: parseFloat(a.cantidad),
              recibido: 0,
              pendiente_recibir: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
              descuento: parseFloat(a.descuento),
              total: parseFloat(a.total),
              observaciones: a.observaciones,
              fecha: fechaEdit
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        habilitacionOrden.map((a)=>{
          axios.patch(
            `${URL_TELAS_OC}/${a.id}`,
            {
              habilitacion: a.habilitacion,
              cantidad: parseFloat(a.cantidad),
              precio: parseFloat(a.precio),
              descuento: parseFloat(a.descuento),
              total: parseFloat(a.total),
              pendiente_recibir: parseFloat(a.pendiente_recibir),
              observaciones: a.observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );
        })
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Orden de Compra",
            detalle: `${numero_orden}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );
        Swal.fire("Good job!", "Creado con exito", `success`);
        setTimeout(() => {
          window.location.reload();
        }, 4000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Oops...",
      text: "Something went wrong!",
      footer: `${error.response.data}`,
    });
    console.log(error);
  }
};


function desautorizar(idEdit) {
  Swal.fire({
    title: "Estas seguro?",
    text: "Se quitara la autorizacion!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, quitar autorizacion!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .patch(
          `${URL_ORDENES_COMPRA}/${idEdit}`,
          {
            autorizado: "No",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          const newComments = comments.map((i) => {
            if (idEdit === i._id) {
              i.autorizado = "No";
            }
            return i;
          });
          setComments(newComments);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Se quito la autorizacion",
            showConfirmButton: false,
            timer: 2000,
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }
  });
}

function autorizar(idEdit) {
  Swal.fire({
    title: "Estas seguro?",
    text: "Se va a autorizar!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, autorizar!",
  }).then((result) => {
    if (result.isConfirmed) {
      axios
        .patch(
          `${URL_ORDENES_COMPRA}/${idEdit}`,
          {
            autorizado: "Si",
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then((response) => {
          const newComments = comments.map((i) => {
            if (idEdit === i._id) {
              i.autorizado = "Si";
            }
            return i;
          });
          setComments(newComments);
          Swal.fire({
            position: "center",
            icon: "success",
            title: "Se Autorizo",
            showConfirmButton: false,
            timer: 2000,
          });
          // setTimeout(() => {
          //   window.location.reload();
          // }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    }
  });
}

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_oc ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.oc_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/OrdenesCompraCreate"
                >
                  Nueva Orden
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Orden
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Ordenes de Compra</h3>
          <div className="row">
                        <div className="col-md-8">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
                        <div className="col-md-4 d-flex flex-row-reverse">
                            <Search
                                onSearch={value => {
                                    setSearch(value);
                                    setCurrentPage(1);
                                }}
                            />
                        </div>
                    </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          
            <Col md={2}>
              <Label>Status</Label>
              <Input
                bsSize="sm"
                type="select"
                value={status}
                required
                onChange={(e) => {
                  setStatus(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Abierto">Abierto</option>
                <option value="Cerrado">Cerrado</option>
              </Input>
            </Col>

            <Col md={2}>
              <Label>Autorizado</Label>
              <Input
                bsSize="sm"
                type="select"
                value={autorizado}
                required
                onChange={(e) => {
                  setAutorizado(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
            </Col>
          </Row>
            <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader headers={headers} onSorting={(field, order) => setSorting({ field, order }) } />
            <tr>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
              </td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedTipo}
                onChange={(e) => {
                  setSelectedTipo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Tela">Tela</option>
                <option value="Habilitacion">Habilitacion</option>
              </Input>
              </td>
              {/* <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedCliente}
                onChange={(e) => {
                  setSelectedCliente(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="0">Selecciona</option>
                {clientes
                  .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.nombre_comercial}</option>;
                  })}
              </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
              <Input
                bsSize="sm"
                type="select"
                value={selectedPendFechaConf}
                onChange={(e) => {
                  setSelectedPendFechaConf(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Si</option>
                <option value="No">No</option>
              </Input>
              </td> */}
            </tr>
            <tbody>
              {commentsData.map((a) => {
                if(
                  (a.nacInt == "Internacional" && validadorInter >= a.fecha_compromiso && a.status == "Abierto") ||
                  (a.nacInt == "Nacional" && validadorNac >= a.fecha_compromiso && a.status == "Abierto")
                  ){
                  return (
                    <tr style={{ backgroundColor: "#ed8c8c"}}>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.proveedores}</td>
                      <td>{a.nacInt}</td>
                      <td>{a.tipo}</td>
                      {/* <td>{a.clientes}</td>
                      <td>{a.pendFechaConf}</td> */}
                      <td>{a.fecha_compromiso}</td>
                      {/* <td>{a.fecha_cita}</td>
                      <td>{a.fecha_cancelacion_pedido}</td>
                      <td>{a.fecha_creacion_prepedido}</td> */}
                      <td>{a.observaciones}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_kilos)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_metros)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total_piezas)}</td>
                      <td>{"$" + new Intl.NumberFormat("en-US").format(a.total_general)} </td>
                      <td>
                        {user.oc_create ? (
                          <div>
                           {user.oc_autorizacion ? (
                             <>
                             {a.autorizado == "Si" ? (
                                  <span>
                                    <Button
                                      color="primary"
                                      id="Baja"
                                      onClick={(e) =>
                                        desautorizar(
                                          a._id
                                        )
                                      }
                                      size="sm"
                                    >
                                      <i class="fas fa-user-check"></i>
                                    </Button>
                                  </span>
                                ) : (
                                  <span>
                                    <Button
                                      color="danger"
                                      onClick={(e) =>
                                        autorizar(
                                          a._id
                                        )
                                      }
                                      size="sm"
                                    >
                                      <i class="fas fa-user-lock"></i>
                                    </Button>
                                  </span>
                                )}
                             </>
                           ) : (undefined)}

                           {a.autorizado == "Si" ? (
                            <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => PDFOC( 
                                a.fecha,
                                a.plazo,
                                a.fecha_compromiso,
                                // a.fecha_cita,
                                a.idProveedores,
                                a.proveedores,
                                // a.clientes,
                                a.total_kilos,
                                a.total_metros,
                                a.total_piezas,
                                a.total_general,
                                a.observaciones,
                                a.numero,
                                a.tipo,
                                a.impuestos,
                                a.empresa,
                                a._id )}
                            ><i class="far fa-file-pdf"></i>
                            </Button>
                           ):(
                              <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              disabled
                            ><i class="far fa-file-pdf"></i>
                            </Button>
                           )}
                            
                             
                            <StatusMultiple
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={process.env.REACT_APP_URL_ORDENES_COMPRA}
                              hijo={`${process.env.REACT_APP_URL_TELAS_OC}/baja`}
                            />
                          </div>
                        ) : undefined}
                        </td>
                        <td>
                        { a.status == "Abierto" ?(
                          <div>
                                      <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => jalaInfo(  
                                        a.fecha,
                                        a.plazo,
                                        a.fecha_compromiso,
                                        a.impuestos,
                                        // a.fecha_cita,
                                        // a.fecha_cancelacion_pedido,
                                        // a.fecha_creacion_prepedido,
                                        a.idProveedores,
                                        // a.idClientes,
                                        a.total_kilos,
                                        a.total_metros,
                                        a.total_piezas,
                                        a.total_general,
                                        a.observaciones,
                                        a.numero,
                                        a.tipo,
                                        a.empresa,
                                        a._id )}
                                      >
                                      <i class="far fa-edit"></i>
                                      </Button>
                                      <Button
                                      color="info"
                                      id="Editar"
                                      size="sm"
                                      onClick={(e) => EditTelas(a._id, a.numero, a.tipo, a.fecha)}
                                      >
                                      <i class="fas fa-cart-plus"></i>
                                      </Button>
                                      </div>
                              ): (
                                <div>
                                  <Button color="info" id="Editar" size="sm" disabled>
                                    <i class="far fa-edit"></i>
                                  </Button>
                                  <Button
                                      color="info"
                                      id="Editar"
                                      size="sm" disabled>
                                      <i class="fas fa-cart-plus"></i>
                                      </Button>
                                </div>
                              )}
                      </td>
                    </tr>
                  );
                }else{
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.proveedores}</td>
                      <td>{a.nacInt}</td>
                      <td>{a.tipo}</td>
                      {/* <td>{a.clientes}</td>
                      <td>{a.pendFechaConf}</td> */}
                      <td>{a.fecha_compromiso}</td>
                      {/* <td>{a.fecha_cita}</td>
                      <td>{a.fecha_cancelacion_pedido}</td>
                      <td>{a.fecha_creacion_prepedido}</td> */}
                      <td>{a.observaciones}</td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.total_kilos)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.total_metros)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US").format(a.total_piezas)}
                      </td>
                      <td>
                        {"$" +
                          new Intl.NumberFormat("en-US").format(
                            a.total_general
                          )}{" "}
                      </td>
                      <td>
                        {user.oc_create ? (
                          <div>
                            {user.oc_autorizacion ? (
                              <>
                                {a.autorizado == "Si" ? (
                                  <span>
                                    <Button
                                      color="primary"
                                      id="Baja"
                                      onClick={(e) =>
                                        desautorizar(
                                          a._id
                                        )
                                      }
                                      size="sm"
                                    >
                                      <i class="fas fa-user-check"></i>
                                    </Button>
                                  </span>
                                ) : (
                                  <span>
                                    <Button
                                      color="danger"
                                      onClick={(e) =>
                                        autorizar(
                                          a._id
                                        )
                                      }
                                      size="sm"
                                    >
                                      <i class="fas fa-user-lock"></i>
                                    </Button>
                                  </span>
                                )}
                              </>
                            ) : undefined}
                            {a.autorizado == "Si" ? (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                onClick={(e) =>
                                  PDFOC(
                                    a.fecha,
                                    a.plazo,
                                    a.fecha_compromiso,
                                    // a.fecha_cita,
                                    a.idProveedores,
                                    a.proveedores,
                                    // a.clientes,
                                    a.total_kilos,
                                    a.total_metros,
                                    a.total_piezas,
                                    a.total_general,
                                    a.observaciones,
                                    a.numero,
                                    a.tipo,
                                    a.impuestos,
                                    a.empresa,
                                    a._id
                                  )
                                }
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                className="btn"
                                color="danger"
                                disabled
                              >
                                <i class="far fa-file-pdf"></i>
                              </Button>
                            )}
                            <StatusMultiple
                              idStatus={a._id}
                              status={a.status}
                              URL_STATUS={
                                process.env.REACT_APP_URL_ORDENES_COMPRA
                              }
                              hijo={`${process.env.REACT_APP_URL_TELAS_OC}/baja`}
                            />
                          </div>
                        ) : undefined}
                      </td>
                      <td>
                        {a.status == "Abierto" ? (
                          <div>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fecha,
                                  a.plazo,
                                  a.fecha_compromiso,
                                  a.impuestos,
                                  // a.fecha_cita,
                                  // a.fecha_cancelacion_pedido,
                                  // a.fecha_creacion_prepedido,
                                  a.idProveedores,
                                  // a.idClientes,
                                  a.total_kilos,
                                  a.total_metros,
                                  a.total_piezas,
                                  a.total_general,
                                  a.observaciones,
                                  a.numero,
                                  a.tipo,
                                  a.empresa,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                EditTelas(a._id, a.numero, a.tipo, a.fecha)
                              }
                            >
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="fas fa-cart-plus"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
                            <Pagination
                                total={totalItems}
                                itemsPerPage={ITEMS_PER_PAGE}
                                currentPage={currentPage}
                                onPageChange={page => setCurrentPage(page)}
                            />
                        </div>
        </div>
      ) : undefined}
      <br />


      

      <Modal size="xxl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Orden de Compra {numero_orden}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              <Col md={2}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  placeholder="Fecha"
                  value={fecha}
                  disabled
                />
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Proveedor</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedProveedorEdit}
                  required
                  onChange={(e) => {
                    setSelectedProveedorEdit(e.target.value);
                  }}
                >
                  <option value="">Selecciona un Proveedor</option>
                  {proveedores
                    .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.razon_social}</option>;
                    })}
                </Input>
              </Col>
              <Col md={2}>
                <Label>Plazo</Label>
                <Input
                  type="text"
                  placeholder="Plazo"
                  value={plazo}
                  required
                  onChange={(e) => {
                    setPlazo(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Compromiso</Label>
                <Input
                  type="date"
                  placeholder="Fecha Compromiso"
                  value={fecha_compromiso}
                  required
                  onChange={(e) => {
                    setFechaCompromiso(e.target.value);
                  }}
                />
              </Col>

              <Col md={2}>
                <Label>Empresa</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={empresa}
                  required
                  onChange={(e) => {
                    setEmpresa(e.target.value)
                  }}
                >
                  <option value="Saparnasa SA de CV">Saparnasa SA de CV</option>
                  <option value="Diseños y Creaciones Jadamo SA de CV">Diseños y Creaciones Jadamo SA de CV</option>
                  <option value="Moda y Diseños David off Jeans SA de CV">Moda y Diseños David off Jeans SA de CV</option>
                  <option value="Pastel Collections SA de CV">Pastel Collections SA de CV</option>
                </Input>
              </Col>

              <Col md={2}>
                <Label>Impuestos</Label>
                <Input
                  type="select"
                  placeholder="Impuestos"
                  value={impuestos}
                  required
                  onChange={(e) => {
                    setImpuestos(e.target.value);
                  }}
                  >
                    <option value="">Selecciona</option>
                    <option value="Si">Si</option>
                    <option value="No">No</option>
                  </Input>
              </Col>
              {/* <Col md={2}>
                <Label>Fecha Cita Recibo</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cita"
                  value={fecha_cita}
                  required
                  onChange={(e) => {
                    setFechaCita(e.target.value);
                  }}
                />
              </Col> */}
            </Row>
            <Row>
            {/* <Col md={2}>
                <Label className="mr-sm-2">Cliente</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedClienteEdit}
                  required
                  onChange={(e) => {
                    setSelectedClienteEdit(e.target.value);
                  }}
                >
                  <option value="">Selecciona un Cliente</option>
                  {clientes
                    .sort((a, b) => (a.nombre_comercial > b.nombre_comercial ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.nombre_comercial}</option>;
                    })}
                </Input>
              </Col>

              <Col md={2}>
                <Label>Fecha Canc. Pedido</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cita"
                  value={fecha_cancelacion_pedido}
                  required
                  onChange={(e) => {
                    setFechaCancelacionPedido(e.target.value);
                  }}
                />
              </Col>
              <Col md={2}>
                <Label>Fecha Creacion PrePedido</Label>
                <Input
                  type="date"
                  placeholder="Fecha Cita"
                  value={fecha_creacion_prepedido}
                  required
                  onChange={(e) => {
                    setFechaCreacionPrePedido(e.target.value);
                  }}
                />
              </Col> */}
              <Col md={6}>
                <Label for="exampleEmail" className="mr-sm-2">
                  Observaciones
                </Label>
                <Input
                  type="textarea"
                  placeholder="Observaciones"
                  value={observaciones}
                  required
                  onChange={(e) => {
                    setObservaciones(e.target.value);
                  }}
                />
              </Col>
            </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" 
          onClick={editOrdenes}
          >
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xxl" isOpen={modalEditTelas} toggle={toggleEditTelas}>
            <ModalHeader toggle={toggleEditTelas}>
              <h4>Editar Telas OC {numero_orden}</h4>
              <h4>
              TOTALES {total_kilos_edit.toFixed(2)} kg. / {total_metros_edit.toFixed(2)} mts. / {total_piezas_edit.toFixed(2)} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general_edit.toFixed(2))}
              </h4>
              </ModalHeader>
            <ModalBody>
            <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Colores</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Descuento</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Observaciones</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {telasOrden.map((ao) => (
                <div key={ao.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="telas"
                        type="select"
                        value={ao.telas}
                        required
                        disabled
                        // onChange={(event)=>{BuscaTelaExistentes(ao.id, event)}}
                      >
                        <option value="0">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre} / {a.tiposTelas[0].name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                        //  bsSize="sm"
                          name="unidad"
                          type="string"
                          value={ao.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                      {tipo=="Tela"?(
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="colores"
                        type="select"
                        value={ao.colores}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      >
                        <option value="0">Selecciona</option>
                        {/* {ao.arrayColoresTela */}
                        {colores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>
                      ):undefined}

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={ao.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentesCantidad(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={ao.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="observaciones"
                        type="text"
                        placeholder="observaciones"
                        value={ao.observaciones}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={ao.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInputExistentes(ao.id, event)
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}

              {/* Agregar mas telas */}

              {inputFields.map((inputField) => (
                <div key={inputField.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="telas"
                        type="select"
                        value={inputField.telas}
                        required
                        onChange={(event)=>{BuscaTela(inputField.id, event)}}
                      >
                        <option value="0">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre} / {a.tiposTelas[0].name}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                        //  bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputField.unidad}
                          disabled
                        >
                        </Input>
                      </Col>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="colores"
                        type="select"
                        value={inputField.colores}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      >
                        <option value="0">Selecciona</option>
                        {/* {inputField.arrayColoresTela */}
                        {colores
                          .sort((a, b) => (a.name > b.name ? 1 : -1))
                          .map((a) => {
                            return <option value={a._id}>{a.name}</option>;
                          })}
                      </Input>
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputField.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputField.precio}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={inputField.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="observaciones"
                        type="text"
                        placeholder="observaciones"
                        value={inputField.observaciones}
                        required
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>

                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputField.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInput(inputField.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFields.length === 1}
                        onClick={() => handleRemoveFields(inputField.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                      >
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={6}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos_edit.toFixed(2)} kg. / {total_metros_edit.toFixed(2)} mts. / {total_piezas_edit.toFixed(2)} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general_edit.toFixed(2))}
              </h4>
              </Col>
              </Row>
             
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" 
              onClick={saveOrdenCompra}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

          <Modal size="xxl" isOpen={modalEditHabilitacion} toggle={toggleEditHabilitacion}>
            <ModalHeader toggle={toggleEditHabilitacion}>
              <h4>Editar Articulos OC {numero_orden}</h4>
              <h4>
              TOTALES {total_kilos_edit.toFixed(2)} kg. / {total_metros_edit.toFixed(2)} mts. / {total_piezas_edit.toFixed(2)} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general_edit.toFixed(2))}
              </h4>
              </ModalHeader>
            <ModalBody>
            <Row>
                <Col md={2}>
                  <Label className="mr-sm-2">Articulo</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Unidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Cantidad</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Precio</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Descuento</Label>
                </Col>
                <Col md={1}>
                  <Label className="mr-sm-2">Observaciones</Label>
                </Col>
                <Col md={2}>
                  <Label className="mr-sm-2">Total</Label>
                </Col>
              </Row>

              {habilitacionOrden.map((ao) => (
                <div key={ao.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="habilitacion"
                        type="select"
                        value={ao.habilitacion}
                        required
                        disabled
                        // onChange={(event)=>{BuscaHabilitacionExistentes(ao.id, event)}}
                      >
                        <option value="0">Selecciona</option>
                        {habilitacion
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}> {a.nombre} / {a.descripcion} </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                        //  bsSize="sm"
                          name="unidad"
                          type="string"
                          value={ao.unidad}
                          disabled
                        >
                        </Input>
                      </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={ao.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentesCantidadHab(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={ao.precio}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentesHab(ao.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={ao.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentesHab(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="observaciones"
                        type="text"
                        placeholder="observaciones"
                        value={ao.observaciones}
                        required
                        onChange={(event) =>
                          handleChangeInputExistentesHab(ao.id, event)
                        }
                      />
                    </Col>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={ao.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInputExistentesHab(ao.id, event)
                        }
                      />
                    </Col>
                  </Row>
                </div>
              ))}

              {/* Agregar mas habilitacion */}

              {inputFieldsHab.map((inputFieldHab) => (
                <div key={inputFieldHab.id}>
                  <Row>
                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="habilitacion"
                        type="select"
                        value={inputFieldHab.habilitacion}
                        required
                        onChange={(event)=>{BuscaHabilitacion(inputFieldHab.id, event)}}
                      >
                        <option value="0">Selecciona</option>
                        {habilitacion
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}> {a.nombre} / {a.descripcion}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={1}>
                        <Input
                        //  bsSize="sm"
                          name="unidad"
                          type="string"
                          value={inputFieldHab.unidad}
                          disabled
                        >
                        </Input>
                      </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="cantidad"
                        type="number"
                        placeholder="Cantidad"
                        value={inputFieldHab.cantidad}
                        required
                        onChange={(event) =>
                          handleChangeInputHab(inputFieldHab.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="precio"
                        type="number"
                        placeholder="Precio"
                        value={inputFieldHab.precio}
                        required
                        onChange={(event) =>
                          handleChangeInputHab(inputFieldHab.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="descuento"
                        type="number"
                        placeholder="Descuento"
                        value={inputFieldHab.descuento}
                        required
                        onChange={(event) =>
                          handleChangeInputHab(inputFieldHab.id, event)
                        }
                      />
                    </Col>

                    <Col md={1}>
                      <Input
                        // bsSize="sm"
                        name="observaciones"
                        type="text"
                        placeholder="observaciones"
                        value={inputFieldHab.observaciones}
                        required
                        onChange={(event) =>
                          handleChangeInputHab(inputFieldHab.id, event)
                        }
                      />
                    </Col>

                    <Col md={2}>
                      <Input
                        // bsSize="sm"
                        name="total"
                        type="number"
                        placeholder="Total"
                        value={inputFieldHab.total}
                        disabled
                        onChange={(event) =>
                          handleChangeInputHab(inputFieldHab.id, event)
                        }
                      />
                    </Col>
                    <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsHab.length === 1}
                        onClick={() => handleRemoveFieldsHab(inputFieldHab.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsHab}
                      tabindex="-1"
                      >
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
                  </Row>
                </div>
              ))}
              <Row>
              <Col md={6}>
              <h4 id="logoutBoton">
              TOTALES {total_kilos_edit.toFixed(2)} kg. / {total_metros_edit.toFixed(2)} mts. / {total_piezas_edit.toFixed(2)} pzas. / {"$" + new Intl.NumberFormat("en-US").format(total_general_edit.toFixed(2))}
              </h4>
              </Col>
              </Row>
             
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" 
              onClick={saveOrdenCompraHab}
              >
                Enviar
              </Button>
            </ModalFooter>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoOrdenesCompra;
