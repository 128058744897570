import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from '../../../contexts/AuthContext'
import TablaMaquileros from "./TablaMaquileros";
import { Button, Row, Table, Col, ButtonGroup } from "reactstrap";
import Header from "../../../layout/Header/Header";
import axios from "axios";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";


function ListadoMaquileros() {
  const { user } = useContext(AuthContext);
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const [proveedores, setProveedores] = useState([]);

  useEffect(() => {
    axios
      .get(URL_PROVEEDORES, { 
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  },[]);

  function PDFTabla() {
    let TotalImporte= 0
    const data = proveedores
    .map((a) => {
        TotalImporte = TotalImporte + a.importe
        return [
          a.nombre_comercial,
          a.razon_social,
          a.RFC,
          a.contaacto,
          a.telefono,
          a.email,
        ];
    });

    const dataPDF = Object.values(data);

    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });


    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 10, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Maquileros`, 20, 30);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Razon Social",
          "RFC",
          "Contacto",
          "Telefono",
          "Email",
        ],
      ],
      body: dataPDFLimpia,
      startY: 40,
    });
    doc.save(`Maquileros.pdf`);
  }

  function excel() {
    const dataExcel = proveedores
    .map((a) => {
        return {
          Nombre: a.nombre_comercial,
          RazonSocial: a.razon_social,
          RFC: a.RFC,
          Contacto: a.contaacto,
          Telefono: a.telefono,
          Email: a.email,
        };
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoMaquileros";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoMaquileros",
        sheetFilter: [
          "Nombre",
          "RazonSocial",
          "RFC",
          "Contacto",
          "Telefono",
          "Email",
          ],
        sheetHeader: [
          "Nombre",
          "RazonSocial",
          "RFC",
          "Contacto",
          "Telefono",
          "Email",
          ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }


  return (
    <>     
     <Header />
    <br />
    <br />
    {user.menu_proveedores  ?(
        <div className="card col-12">
          <Row>
          <Col md={8}>
              {user.proveedores_create  ?(
              <Button size="sm" type="submit" className="btn btn-success" href="/MaquilerosCreate">Nuevo Maquilero</Button>
              ):(
                <Button size="sm" type="submit" className="btn btn-success" disabled>Nuevo Mauilero</Button>
              )}
              <Button
              size="sm"
              href="/MenuCatalogos"
              className="btn btn-danger"
              id="botonListado"
            >
              Regresar
            </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
               
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
            </Row>
                <h3 align="center">Maquileros</h3>
                <Table size="sm" striped borderless className="table-responsive-xl">
                  <thead>
                    <tr>
                      <th className="tituloTabla">
                        Nombre Comercial
                      </th>
                      <th className="tituloTabla">
                        Razon Social
                      </th>
                      <th className="tituloTabla">
                        RFC
                      </th>
                      <th className="tituloTabla">
                        Contacto
                      </th>
                      <th className="tituloTabla">
                        Telefono
                      </th>
                      <th className="tituloTabla">
                        Email
                      </th>
                      {/* <th className="tituloTabla">
                        Grupo
                      </th> */}
                      <th className="tituloTabla">
                        Editar
                      </th>
                    </tr>
                    </thead>
                    <tbody>
                    {proveedores.map((p) => {
                      if(p.tipo == "Maquilero"){
                      return (
                        <tr>
                          <TablaMaquileros
                            nomcom={p.nombre_comercial}
                            razsoc={p.razon_social}
                            RFC={p.RFC}
                            contacto={p.contacto}
                            telefono={p.telefono}
                            email={p.email}
                            _id={p._id} is_active={p.is_active}
                            calle={p.calle}
                            numero_ext={p.numero_ext}
                            numero_int={p.numero_int}
                            delegacion={p.delegacion}
                            estado={p.estado}
                            pais={p.pais}
                            cp={p.cp}
                            colonia={p.colonia}
                            ciudad={p.ciudad}
                            idProveedor={p._id}
                          />
                        </tr>
                      )}
                    })}
                  </tbody>
                </Table>
        </div>
      ): undefined } 
    </>
  );
}

export default ListadoMaquileros;
