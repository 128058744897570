import React, { useState } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";

function Status(props) {
  const [text, setText] = useState(false);
  const URL_STATUS = `${props.URL_STATUS}/${props.idStatus}`;

  function abierto() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se abrirá!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Abrir!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_STATUS,
            {
              status: "Abierto",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Abrio",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function cerrar() {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se Cerrará!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cerrar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .patch(
            URL_STATUS,
            {
              status: "Cerrado",
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          )
          .then((response) => {
            Swal.fire({
              position: "center",
              icon: "success",
              title: "Se Cerró",
              showConfirmButton: false,
              timer: 2000,
            });
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  return (
    <>
      {props.status == "Abierto" ? (
        <span>
          <Button
            color="primary"
            id="Cerrar"
            onClick={cerrar}
            size="sm"
          >
          <i class="fas fa-lock-open"></i>
          </Button>
        </span>
      ) : (
        <span>
          <Button color="danger" onClick={abierto} size="sm">
            <i class="fas fa-lock"></i>
          </Button>
        </span>
      )}
    </>
  );
}

export default Status;

