import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Form,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";

function ListadoSalidaTela() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_SALIDA_TELA = process.env.REACT_APP_URL_SALIDA_TELA;
  const URL_DETALLE_SALIDA_TELA = process.env.REACT_APP_URL_DETALLE_SALIDA_TELA;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;

  const [telas, setTelas] = useState([]);
  const [salidasTela, setSalidasTela] = useState([]);
 

  const [inventarios, setInventarios] = useState([]);
  const [totalMetros, setTotalMetros] = useState("");
  const [totalKilos, setTotalKilos] = useState("");
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);
  const [rollosTotales, setRollosTotales] = useState(0);

  const [totalCaptura, setTotalCaptura] = useState(1);

  const [idEditTela, setIdEditTela] = useState("");
  const [idSalidaTela, setIdSalidaTela] = useState("");
  const [fecha, setFecha] = useState("");
  const [observaciones, setObservaciones] = useState("NA")

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalTela, setModalTela] = useState(false);
  const toggleTela = () => setModalTela(!modalTela);
  const [validaBoton, setValidaBoton] = useState(true);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      idInventarioTelas: "",
      telas: "",
      idTela: "",
      unidad: "",
      colores: "",
      idColor: "",
      cantidadOriginal: 0,
      cantidad: 0,
      idArticulo: "",
      compras: "",
      telasOC: "",
      telaCodigo: "",
      telaNombre: "",
      telaColor: "",
    },
  ]);

  const [activo, setActivo] = useState("");

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useEffect(() => {
    axios
      .get(URL_SALIDA_TELA, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTela = response.data;
        // Array para pagination
        let arrayTabla = allTela
          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              fecha: a.fecha,
              observaciones: a.observaciones,
              idSalidaTela: a.idSalidaTela,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setSalidasTela(allTela);
        setComments(dataFinal);
        //

      })
      .catch((err) => {
        console.log(err);
      });
        axios
        .get(`${URL_INVENTARIOS_TELAS}Existencia`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allInventarios = response.data;
          setInventarios(allInventarios);
        })
        .catch((err) => {
          console.log(err);
        });

      //   axios
      // .get(URL_TELAS, {
      //   headers: {
      //     Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      //   },
      // })
      // .then((response) => {
      //   let allTela = response.data;
      //   // Array para pagination
      //   let arrayTabla = allTela
      //     .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
      //     .map((a) => {
      //       return {
      //         _id: a._id,
      //         nombre: a.nombre + a.descripcion,
      //         unidad: a.unidad,
      //         observaciones: a.observaciones,
      //         descripcion: a.descripcion,
      //       };
      //     })
      //     .filter(function (el) {
      //       return el != null;
      //     });

      //   let dataFinal = Object.values(arrayTabla);

      //   setTelas(dataFinal);
      //   //
      // })
      // .catch((err) => {
      //   console.log(err);
      // });
   
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.nombre,
          "$" + new Intl.NumberFormat("en-US").format(a.costo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Tela`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Costo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Tela.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (activo == 0 || activo == a.is_active) {
        return {
          Nombre: a.nombre,
          Costo: a.costo,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoSalidaTela";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoSalidaTela",
        sheetFilter: [
          "Nombre",
          "Costo",
        ],
        sheetHeader: [
          "Nombre",
          "Costo",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        // (selectedTiposTelaFiltro == 0 || selectedTiposTelaFiltro == a.grupos[0]._id) &&
        activo == 0 ||
        activo == a.is_active
      ) {
        return [
          a.nombre,
          "$" + new Intl.NumberFormat("en-US").format(a.costo),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Tela`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Nombre",
          "Costo",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Tela",
          email: mailTo,
          fileName: "ListadoSalidaTela.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Tela.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const handleChangeInputTela = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFields(newInputFields);
  };  
  
  const handleChangeInputTelaCant = (id, event) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i[event.target.name] = parseFloat(event.target.value);
      }
      return i;
    });
    setInputFields(newInputFields);
  };



  function saveTela(event) {
    event.preventDefault();
    let totalRollos = inputFields.length;
    axios
      .post(
        URL_SALIDA_TELA,
        {
          fecha: fecha,
          observaciones: observaciones,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then((data) => {
        inputFields.map((a) => {
          if (a.cantidad != 0) {
            axios
              .post(
                URL_DETALLE_SALIDA_TELA,
                {
                  cantidad: a.cantidad,
                  salidaTela: data.data._id,
                  inventariosTelas: a.idInventarioTelas,
                  telas: a.idTela,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              )
              .then(() => {
                totalRollos = totalRollos - 1;
                if (totalRollos == 0) {
                  axios
                    .post(
                      URL_LOGS,
                      {
                        tipo: "Salida de Tela",
                        detalle: `Salida ${data.data.idSalidaTela}`,
                        user: user.id,
                      },
                      {
                        headers: {
                          Authorization: `Bearer: ${localStorage.getItem(
                            "app_token"
                          )}`,
                        },
                      }
                    )
                    .then(() => {
                      Swal.fire("Good job!", "Creado con exito", "success");
                      setTimeout(() => {
                        window.location.reload();
                      }, 1000);
                    })
                    .catch((error) => {
                      Swal.fire({
                        icon: "error",
                        title: "Oops...",
                        text: "Something went wrong!",
                        footer: `${error.response.data}`,
                      });
                      console.log(error);
                      setValidaBoton(true);
                    });
                }
              });
          } else {
            totalRollos = totalRollos - 1;
            if (totalRollos == 0) {
              axios
                .post(
                  URL_LOGS,
                  {
                    tipo: "Salida de Tela",
                    detalle: `Pedido ${data.data.idSalidaTela}`,
                    user: user.id,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  Swal.fire("Good job!", "Creado con exito", "success");
                  setTimeout(() => {
                    window.location.reload();
                  }, 1000);
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                    footer: `${error.response.data}`,
                  });
                  console.log(error);
                  setValidaBoton(true);
                });
            }
          }
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
        setValidaBoton(true);
      });
  }

  // function importarArchivo(event){
  //   const target = event.target;
  //   const value = target.type === "checkbox" ? target.checked : target.value;
  //   const name = target.name;
  //   let hojas = [];
   
  //   if (name === "file") {
  
  //     let reader = new FileReader();
  //       reader.readAsArrayBuffer(target.files[0]);
  //       reader.onloadend = (e) => {
  //         var data = new Uint8Array(e.target.result);
  //         var workbook = XLSX.read(data, { type: "array" });
  
  //         workbook.SheetNames.forEach(function (sheetName) {
  //           // Here is your object
  //           var XL_row_object = XLSX.utils.sheet_to_json(
  //             workbook.Sheets[sheetName]
  //           );
  //           hojas.push({
  //             data: XL_row_object,
  //             sheetName,
  //           });
  //         });
  
  //         let A1 = hojas[0].data
  
  //         let TC = totalCaptura
  //         A1.map((a)=>{
  //            TC = TC + 1
  //           setTotalCaptura(TC)
  //         inputFields.push({
  //           id: uuidv4(),
  //           idTela: a.idTela,
  //           cantidad: (a.CantidadEdit) * -1
  //         })
  //         })
  
  //       }
  //   }
  // }
  console.log("hola2", inputFields)

  async function PDFOC(
    fecha,
    observaciones,
    numero,
    idPDFOC
  ) {
    await axios
      .get(`${URL_DETALLE_SALIDA_TELA}/proceso/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulosPedido = response.data;
        let arrayTabla = allArticulosPedido
          .map((a) => {
            if (a.cantidad != 0) {
              return {
                tela: a.inventariosTelas[0].telas[0].codigo  + " " + a.inventariosTelas[0].telas[0].nombre,
                color: a.inventariosTelas[0].colores[0].name,
                cantidad: a.cantidad,
              };
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        const data = dataFinal.map((a) => {
          return [
            a.tela,
            a.color,
            new Intl.NumberFormat("en-US").format(a.cantidad),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Salida Tela # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Observaciones ${observaciones}`, 20, 30);
        doc.autoTable({
          head: [
            [
              "Tela",
              "Color",
              "Cantidad",
            ],
          ],
          body: dataPDFLimpia,
          startY: 50,
          // foot: [
          //   [
          //     "Total",
          //     "$" + new Intl.NumberFormat("en-US").format(total_general),
          //   ],
          // ],
          showFoot: "lastPage",
        });
        // doc.save(`Pedido-${numero}.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function jalaInfo(
    fecha,
    observaciones,
    idSalidaTela,
    idEdit
  ) {
    setFecha(fecha);
    setObservaciones(observaciones);
    setIdSalidaTela(idSalidaTela);
    setIdEditTela(idEdit);
    toggleEdit();
  }

  function abreModal() {
    setFecha("");
    setObservaciones("NA");
    toggleTela();
  }

  function editTela(event) {
    event.preventDefault();
    const URL_SALIDA_TELA_EDIT = `${process.env.REACT_APP_URL_SALIDA_TELA}/${idEditTela}`;
    if (file == null) {
      axios
        .all([
          axios.patch(
            URL_SALIDA_TELA_EDIT,
            {
              fecha,
              observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
        ])
        .then(() => {
          axios.post(
            URL_LOGS,
            {
              tipo: "Editar Salida Tela",
              detalle: `${idSalidaTela}`,
              user: user.id,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          );

          toggleEdit();
          Swal.fire("Good job!", "Actualizado con exito", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1000);
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            footer: `${error.response.data}`,
          });
          console.log(error);
        });
    } else {
      axios
        .all([
          axios.patch(
            URL_SALIDA_TELA_EDIT,
            {
              fecha,
              observaciones,
            },
            {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            }
          ),
        ])

        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("tela", idEditTela);

          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Salida Tela",
                  detalle: `${idSalidaTela}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );

              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Numero", field: "idSalidaTela", sortable: true },
    { name: "Fecha", field: "fecha", sortable: true },
    { name: "Observaciones", field: "observaciones", sortable: true },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.fecha.toLowerCase().includes(search.toLowerCase()) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase())
      );
    }


    setTotalItems(computedComments.length);

     //Sorting comments
     if (
      sorting.field &&
      sorting.field != "idSalidaTela"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "idSalidaTela")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "idSalidaTela")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
  ]);
console.log("hola", inventarios)
  function BuscaRollo(id, event) {
    if (event.target.value.length >= 24) {
      if (inventarios.filter((e) => e._id == event.target.value)
      .length > 0)  {
      if (
        (inputFields.filter((e) => e.idInventarioTelas == event.target.value)
          .length > 0) 
      ) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "El rollo ya fue escaneado!",
          showConfirmButton: false,
        });
        const newInputFields = inputFields.map((i) => {
          if (id === i.id) {
            i.idInventarioTelas = "";
          }
          return i;
        });
        setInputFields(newInputFields);
      } else {
        inventarios.map((a) => {
          if (a._id == event.target.value) {
            let idInventarioTelas = a._id;
            let telas = a.telas[0].nombre;
            let idTela = a.telas[0]._id;
            let unidad = a.telas[0].unidad;
            let color = a.colores[0].name;
            let idColor = a.colores[0]._id;
            let cantidad = a.cantidad;
            let telaNombre = a.telas[0].nombre + " / " + a.colores[0].name
            
            handleChangeInputTelas(
              id,
              idInventarioTelas,
              telas,
              idTela,
              unidad,
              color,
              idColor,
              cantidad,
              telaNombre
            );
          }
        });
      }
    } else {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "El rollo ya fue cortado previamente!",
        showConfirmButton: false,
      });
      const newInputFields = inputFields.map((i) => {
        if (id === i.id) {
          i.idInventarioTelas = "";
        }
        return i;
      });
      setInputFields(newInputFields);
    }
    } 
    // else {
    //   const newInputFields = inputFields.map((i) => {
    //     if (id === i.id) {
    //       i.idInventarioTelas = event.target.value;
    //     }
    //     return i;
    //   });
    //   setInputFields(newInputFields);
    // }
  }

  const handleChangeInputTelas = (
    id,
    idInventarioTelas,
    telas,
    idTela,
    unidad,
    color,
    idColor,
    cantidad,
    telaNombre
  ) => {
    const newInputFields = inputFields.map((i) => {
      if (id === i.id) {
        i.idInventarioTelas = idInventarioTelas;
        i.telas = telas;
        i.idTela = idTela;
        i.unidad = unidad;
        i.colores = color;
        i.idColor = idColor;
        i.cantidad = cantidad;
        i.telaNombre = telaNombre;
      }
      return i;
    });

    ActualizaTotalesSurt();
    setInputFields(newInputFields);
    handleAddFields();
  };

  function ActualizaTotalesSurt() {
    let mts = inputFields.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = inputFields.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);

    let agrupado = inputFields.reduce(function (groups, item) {
      const val = item["idArticulo"] + item["idColor"] + item["idTela"];
      groups[val] = groups[val] || {
        idArticulo: item.idArticulo,
        idColor: item.idColor,
        idTela: item.idTela,
        cantidad: 0,
      };
      groups[val].cantidad += item.cantidad;
      groups[val].telas = item.telas;
      groups[val].idTela = item.idTela;
      groups[val].idArticulo = item.idArticulo;
      groups[val].unidad = item.unidad;
      groups[val].idColor = item.idColor;
      return groups;
    }, []);

    let CA = Object.values(agrupado);
    setCantidadAgrupado(CA);
    let tr = 0;
    inputFields.map((a) => {
      if (a.idArticulo != "") {
        return (tr = tr + 1);
      }
    });
    setRollosTotales(tr);
  }
  const handleAddFields = () => {
    const ultimo = inputFields[inputFields.length - 1];

    setInputFields([
      ...inputFields,
      {
        id: uuidv4(),
        idInventarioTelas: "",
        telas: "",
        idTela: "",
        unidad: "",
        colores: "",
        idColor: "",
        cantidad: 0,
        idArticulo: ultimo.idArticulo,
      },
    ]);
  };

  const handleRemoveFields = (id) => {
    const values = [...inputFields];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFields(values);
    let mts = values.filter((e) => e.unidad == "Metros");
    let totalMts = mts.map((c) => parseFloat(c.cantidad));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalMetros(TM);
    let KG = values.filter((e) => e.unidad == "Kilos");
    let totalKG = KG.map((c) => parseFloat(c.cantidad));
    let TK = totalKG.reduce((t, total, index) => t + total, 0);
    setTotalKilos(TK);
  };

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_telas ? (
        <div className="card container col-8">
          <Row>
            <Col md={8}>
              {user.menu_telas ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  onClick={abreModal}
                >
                  Nueva Salida
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Salida
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCatalogos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            {/* <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col> */}
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Salida Tela</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Salidas Tela</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.idSalidaTela}</td>
                      <td>{a.fecha}</td>
                      <td>{a.observaciones}</td>
                      <td>
                      <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.observaciones,
                                a.idSalidaTela,
                                a._id
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Salida Tela {idSalidaTela}
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={3}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>
            <Col md={9}>
              <Label>
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editTela}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="xl" isOpen={modalTela} toggle={toggleTela}>
        <ModalHeader toggle={toggleTela}>
          <h3>Salida Tela</h3>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={saveTela}>
            <Row>
              <Col md={3}>
                <Label>Fecha</Label>
                <Input
                  type="date"
                  value={fecha}
                  onChange={(event) => {
                    setFecha(event.target.value);
                  }}
                ></Input>
              </Col>
            <Col md={6}>
              <Label>
                Observaciones
              </Label>
              <Input
                type="text"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
            {/* <Col md={3}>
              <Input
                required
                type="file"
                name="file"
                id="file"
                onChange={(event) => importarArchivo(event)}
              />
            </Col> */}
            </Row>
            <br />
            <Row>
              <Col md={4}>
                <Label className="mr-sm-2">Codigo</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Tela</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Color</Label>
              </Col>
              <Col md={2}>
                <Label className="mr-sm-2">Cantidad</Label>
              </Col>
            </Row>

            {inputFields.map((inputField) => (
              <div key={inputField.id}>
                <Row>
                <Col md={4}>
                    <Input
                      bsSize="sm"
                      name="idInventarioTelas"
                      type="string"
                      value={inputField.idInventarioTelas}
                      onChange={(event) => {
                        BuscaRollo(inputField.id, event);
                      }}
                    ></Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="colores"
                      type="text"
                      value={inputField.colores}
                      disabled
                      // onChange={(event) => {
                      //   handleChangeInputTelaCant(inputField.id, event);
                      // }}
                    ></Input>
                  </Col>
                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="telas"
                      type="text"
                      value={inputField.telas}
                      disabled
                      // onChange={(event) => {
                      //   handleChangeInputTelaCant(inputField.id, event);
                      // }}
                    ></Input>
                  </Col>

                  <Col md={2}>
                    <Input
                      bsSize="sm"
                      name="cantidad"
                      type="number"
                      value={inputField.cantidad}
                      disabled
                      // onChange={(event) => {
                      //   handleChangeInputTelaCant(inputField.id, event);
                      // }}
                    ></Input>
                  </Col>
                  <Col>
                    <Button
                      size="sm"
                      className="btn"
                      color="danger"
                      disabled={inputFields.length === 1}
                      onClick={() =>
                        handleRemoveFields(inputField.id)
                      }
                      tabindex="-1"
                    >
                      <i class="fas fa-minus"></i>
                    </Button>
                    <Button
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFields}
                      tabindex="-1"
                    >
                      <i class="fas fa-plus"></i>
                    </Button>
                  </Col>
                </Row>
              </div>
            ))}
            <br />
            {validaBoton ? (
              <Button type="submit" className="btn btn-success">
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
          </Form>
        </ModalBody>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoSalidaTela;
