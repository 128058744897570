import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import { encode } from "base64-arraybuffer";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import StatusMultiple from "../../StatusMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import moment from "moment";


function ListadoTraspasosEntrada() {
  const { user } = useContext(AuthContext);
  const URL_AREAS = process.env.REACT_APP_URL_AREAS;
  const URL_ARTICULOS_TE = process.env.REACT_APP_URL_ARTICULOS_TE;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_TRASPASOS_ENTRADA = process.env.REACT_APP_URL_TRASPASOS_ENTRADA;
  const AREAS_GENERAL = process.env.REACT_APP_AREAS_GENERAL;
  let date = new Date();
  let hoy = moment(date).format("YYYY-MM-DD");
  let inicio = new Date()
  inicio.setDate(inicio.getDate()-60);
  let startDate   = moment(inicio).format("YYYY-MM-DD");

  const [traspasos, setTraspasos] = useState([]);

  const [areas, setAreas] = useState([]);

  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(hoy);
  const [selectedAreaSalida, setSelectedAreaSalida] = useState("");
  const [selectedAreaDestino, setSelectedAreaDestino] = useState("");

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
    .get(`${URL_TRASPASOS_ENTRADA}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTraspasos = response.data;
        // Array para pagination
        let arrayTabla = allTraspasos
          .sort((a, b) => (a.idTraspasosEntrada < b.idTraspasosEntrada ? 1 : -1))
          .map((a) => {
            if(
              (user.areas == AREAS_GENERAL)
              ){
            return {
              _id: a._id,
              numero: a.idTraspasosEntrada,
              activo: a.is_active,
              fecha: a.fecha,
              observaciones: a.observaciones,
              total: a.total,
              areaSalida: a.traspasosSalida[0].areaSalida[0].name,
              areaDestino: a.traspasosSalida[0].areaDestino[0].name,
              idAreaSalida: a.traspasosSalida[0].areaSalida[0]._id,
              idAreaDestino: a.traspasosSalida[0].areaDestino[0]._id,
              recibida: a.traspasosSalida[0].recibida,
            } }else if(user.areas == a.traspasosSalida[0].areaDestino[0]._id || user.areas == a.traspasosSalida[0].areaSalida[0]._id){
              return {
                _id: a._id,
                numero: a.idTraspasosEntrada,
                activo: a.is_active,
                fecha: a.fecha,
                observaciones: a.observaciones,
                total: a.total,
                areaSalida: a.traspasosSalida[0].areaSalida[0].name,
                areaDestino: a.traspasosSalida[0].areaDestino[0].name,
                idAreaSalida: a.traspasosSalida[0].areaSalida[0]._id,
                idAreaDestino: a.traspasosSalida[0].areaDestino[0]._id,
                recibida: a.traspasosSalida[0].recibida,
              }
            }
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        setTraspasos(allTraspasos);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_AREAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allAreas = response.data;
        setAreas(allAreas);
      })
      .catch((err) => {
        console.log(err);
      });
    }, [selectedFechaInicio, selectedFechaFin, user]);

  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedAreaSalida == 0 || selectedAreaSalida == a.idAreaSalida) &&
        (selectedAreaDestino == 0 || selectedAreaDestino == a.idAreaDestino) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.numero,
          a.fecha,
          a.observaciones,
          a.areaSalida,
          a.areaDestino,
          new Intl.NumberFormat("en-US").format(a.total),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Traspasos Entraada`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        ["Numero", "Fecha", "Observaciones", "Almacen Salida", "Almacen Destino", "Cantidad"],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save(`Traspasos-Entrada.pdf`);
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedAreaSalida == 0 || selectedAreaSalida == a.idAreaSalida) &&
        (selectedAreaDestino == 0 || selectedAreaDestino == a.idAreaDestino) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return {
          Numero: a.numero,
          Fecha: a.fecha,
          Observaciones: a.observaciones,
          AreaSalida: a.areaSalida,
          AreaDestino: a.areaDestino,
          Cantidad: a.total,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoTraspasosEntrada";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoTraspasosEntrada",
        sheetFilter: [
          "Numero",
          "Fecha",
          "Observaciones",
          "AreaSalida",
          "AreaDestino",
          "Cantidad",
        ],
        sheetHeader: [
          "Numero",
          "Fecha",
          "Observaciones",
          "AreaSalida",
          "AreaDestino",
          "Cantidad",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (selectedAreaSalida == 0 || selectedAreaSalida == a.idAreaSalida) &&
        (selectedAreaDestino == 0 || selectedAreaDestino == a.idAreaDestino) &&
        (activo == 0 || activo == a.is_active)
      ) {
        return [
          a.numero,
          a.fecha,
          a.observaciones,
          a.areaSalida,
          a.areaDestino,
          new Intl.NumberFormat("en-US").format(a.total),
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado Traspasos Entrada`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        ["Numero", "Fecha", "Observaciones", "Almacen Salida", "Almacen Destino", "Cantidad"],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode(att);

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Traspasos Entrada",
          email: mailTo,
          fileName: "ListadoTraspasosEntrada.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Entradas de Traspasos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }


  async function PDFOC(
    numero,
    fecha,
    observaciones,
    areaSalida,
    areaDestino,
    total,
    idPDFOC
  ) {
    await axios
      .get(`${URL_ARTICULOS_TE}/traspasoEntrada/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        let arrayTabla = allArticulos
          .map((a) => {
            return {
              id: a._id,
              articulos: a.articulos[0].descripcion,
              cantidad: a.cantidad,
              precio: a.articulos[0].venta,
              total: parseFloat(a.articulos[0].venta) * parseFloat(a.cantidad),
              color: a.colores[0].name,
              talla: a.tallas[0].name
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);

        let cant = dataFinal.map((c) => parseFloat(c.cantidad));
        let TC = cant.reduce((t, total, index) => t + total, 0);

        let TT = dataFinal.map((c) => parseFloat(c.total));
        let TTT = TT.reduce((t, total, index) => t + total, 0);

        const data = dataFinal.map((a) => {
          return [
            a.articulos + " " + a.color + " " + a.talla,
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.precio),
            new Intl.NumberFormat("en-US").format(a.cantidad),
            new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(a.total),
          ];
        });
        const dataPDF = Object.values(data);
        const dataPDFLimpia = dataPDF.filter(function (el) {
          return el != null;
        });
        const doc = new jsPDF("landscape");
        var img = new Image();
        img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
        doc.addImage(img, "png", 230, 30, 45, 15);
        var img2 = new Image();
        img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
        doc.addImage(img2, "png", 120, 15, 60, 25);
        doc.text(`Traspaso Entrada # ${numero}`, 20, 20);
        doc.setFontSize(12);
        doc.text(`Fecha ${fecha}`, 20, 25);
        doc.text(`Almacen Salida ${areaSalida}`, 20, 30);
        doc.text(`Almacen Destino ${areaDestino}`, 20, 35);
        doc.text(
          `Piezas ${new Intl.NumberFormat("en-US").format(TC)}`,
          20,
          40
        );
        doc.autoTable({
          head: [["Articulo", "Precio", "Cantidad", "Total"]],
          body: dataPDFLimpia,
          startY: 45,
          foot: [
            [
              "",
              "Totales",
              new Intl.NumberFormat("en-US").format(TC),
              new Intl.NumberFormat("en-US", {style: "currency", currency: "USD", maximumFractionDigits: 2}).format(TTT),
            ],
          ],
          showFoot: "lastPage",
        });
        doc.save(`Traspaso-Entrada-${numero}.pdf`);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const headers = [
    // { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Observaciones", field: "observaciones", sortable: true },
    { name: "Almacen Salida", field: "areaSalida", sortable: true },
    { name: "Almacen Destino", field: "areaDestino", sortable: true },
    { name: "Cantidad", field: "total", sortable: true },
    { name: "PDF", field: "PDF", sortable: false },
    // { name: "Editar", field: "editar", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          // comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.numero.toString().includes(search) ||
          comment.observaciones.toLowerCase().includes(search.toLowerCase()) ||
          comment.areaSalida.toLowerCase().includes(search.toLowerCase()) ||
          comment.areaDestino.toLowerCase().includes(search.toLowerCase()) ||
          comment.total.toString().includes(search)
      );
    }
    if (selectedAreaSalida) {
      computedComments = computedComments.filter((e) =>
        e.idAreaSalida.includes(selectedAreaSalida)
      );
    }
    if (selectedAreaDestino) {
      computedComments = computedComments.filter((e) =>
        e.idAreaDestino.includes(selectedAreaDestino)
      );
    }
    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "total" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "total" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "total" || sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
    selectedAreaSalida,
    selectedAreaDestino
  ]);

 
  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_traspasos_entrada ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.traspasos_entrada_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/TraspasosEntradaCreate"
                >
                  Nueva Entrada
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Entrada
                </Button>
              )}
              {/* <Button
                size="sm"
                href="/MenuInventarios"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Entrada de Traspasos</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Entradas de Traspasos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAreaSalida}
                  onChange={(e) => {
                    setSelectedAreaSalida(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedAreaDestino}
                  onChange={(e) => {
                    setSelectedAreaDestino(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {areas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>

            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.observaciones}</td>
                      <td>{a.areaSalida}</td>
                      <td>{a.areaDestino}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.total)}</td>
                      <td>
                      {user.traspasos_entrada_create ? (
                          <div>
                             <Button
                              size="sm"
                              className="btn"
                              color="danger"
                              onClick={(e) => PDFOC( 
                                a.numero,
                                a.fecha,
                                a.observaciones,
                                a.areaSalida,
                                a.areaDestino,
                                a.total,
                                a._id 
                                )}
                            ><i class="far fa-file-pdf"></i>
                            </Button>
                            </div>
                        ) : undefined}
                        </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      
      {loader}
    </>
  );
}

export default ListadoTraspasosEntrada;
