import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import BajaAltaMultiple from "../../BajaAltaMultiple";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import { v4 as uuidv4 } from "uuid";
import qrcode from "qrcode-js";
import moment from "moment";

function ListadoCompras() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_COMPRAS = process.env.REACT_APP_URL_COMPRAS;
  const URL_COMPRAS_ACUMULADOS = process.env.REACT_APP_URL_COMPRAS_ACUMULADOS;
  const URL_INVENTARIOS_TELAS = process.env.REACT_APP_URL_INVENTARIOS_TELAS;
  const URL_PROVEEDORES = process.env.REACT_APP_URL_PROVEEDORES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;

  let hoy = new Date();
  let inicio = new Date();
  inicio.setDate(inicio.getDate() - 60);

  let endDate = moment(hoy).format("YYYY-MM-DD");
  let startDate = moment(inicio).format("YYYY-MM-DD");

  const [proveedores, setProveedores] = useState([]);
  const [selectedProveedor, setSelectedProveedor] = useState("");
  const [selectedTipo, setSelectedTipo] = useState("");
  const [selectedFechaInicio, setSelectedFechaInicio] = useState(startDate);
  const [selectedFechaFin, setSelectedFechaFin] = useState(endDate);

  const [fecha, setFecha] = useState("");
  const [factura_proveedor, setFacturaProveedor] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [pais_origen, setPaisOrigen] = useState("");
  const [numero_compra, setNumeroCompra] = useState("");
  const [idEdit, setIdEdit] = useState("");

  const [articulosFaltante, setArticulosFaltante] = useState([]);
  const [totalFaltante, setTotalFaltante] = useState(0);
  const [modalFaltante, setModalFaltante] = useState(false);
  const toggleFaltante = () => setModalFaltante(!modalFaltante);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [activo, setActivo] = useState("");

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  useMemo(() => {
    axios
      .get(`${URL_COMPRAS}Fechas/${selectedFechaInicio}/${selectedFechaFin}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCompras = response.data;
        // Array para pagination
        let arrayTabla = allCompras
          .sort((a, b) => (a.idCompras > b.idCompras ? 1 : -1))
          .map((a) => {
            return {
              activo: a.is_active,
              _id: a._id,
              numero: a.idCompras,
              fecha: a.fecha,
              observaciones: a.observaciones,
              kilos: a.total_kilos.toFixed(2),
              metros: a.total_metros.toFixed(2),
              piezas: a.total_piezas.toFixed(2),
              factura_proveedor: a.factura_proveedor,
              iduser: a.user[0]._id,
              user: a.user[0].nombre + " " + a.user[0].apellido,
              pais_origen: a.pais_origen,
              total_costo: a.total_costo,
              subtotal: a.subtotal,
              iva: a.iva,
              idOrdenesCompra: a.ordenesCompra[0]._id,
              numeroOrden: a.ordenesCompra[0].idOrdenCompra,
              fecha_orden_compra: a.ordenesCompra[0].fecha,
              proveedores: a.ordenesCompra[0].proveedores[0].razon_social,
              idProveedores: a.ordenesCompra[0].proveedores[0]._id,
              idOrdenCompra: a.ordenesCompra[0]._id,
              tipo: a.ordenesCompra[0].tipo,
              observacionesOrden: a.ordenesCompra[0].observaciones,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(URL_PROVEEDORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allProveedores = response.data;
        setProveedores(allProveedores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [selectedFechaInicio, selectedFechaFin]);

  const ListaCompras = comments.sort((a, b) =>
    a.idCompras > b.idCompras ? 1 : -1
  );

  function PDFTabla() {
    const data = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.proveedores,
          a.factura_proveedor,
          a.pais_origen,
          a.numeroOrden,
          new Intl.NumberFormat("en-US").format(a.kilos),
          new Intl.NumberFormat("en-US").format(a.metros),
          new Intl.NumberFormat("en-US").format(a.piezas),
          a.user,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura Proveedor",
          "Pais Origen",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    // doc.save(`Compras.pdf`);
    window.open(doc.output("bloburl"), "_blank");
  }

  function excel() {
    const dataExcel = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return {
          Activo: a.activo,
          Numero: a.numero,
          Fecha: a.fecha,
          Proveedores: a.proveedores,
          Factura: a.factura_proveedor,
          Pais: a.pais_origen,
          OC: a.numeroOrden,
          Kilos: a.kilos,
          Metros: a.metros,
          Piezas: a.piezas,
          Recibio: a.user,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "ListadoCompras";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "ListadoCompras",
        sheetFilter: [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura",
          "Pais",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
        sheetHeader: [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura",
          "Pais",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = ListaCompras.map((a) => {
      if (
        (selectedFechaInicio == 0 || selectedFechaInicio <= a.fecha) &&
        (selectedFechaFin == 0 || selectedFechaFin >= a.fecha) &&
        (activo == 0 || activo == a.is_active) &&
        (selectedProveedor == 0 || selectedProveedor == a.idProveedores)
      ) {
        return [
          a.activo,
          a.numero,
          a.fecha,
          a.proveedores,
          a.factura_proveedor,
          a.pais_origen,
          a.numeroOrden,
          new Intl.NumberFormat("en-US").format(a.kilos),
          new Intl.NumberFormat("en-US").format(a.metros),
          new Intl.NumberFormat("en-US").format(a.piezas),
          a.user,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 120, 15, 60, 25);
    doc.text(`Listado de Compras`, 20, 30);
    doc.text(`${selectedFechaInicio} - ${selectedFechaFin}`, 20, 40);
    doc.autoTable({
      head: [
        [
          "Activo",
          "Numero",
          "Fecha",
          "Proveedor",
          "Factura Proveedor",
          "Pais Origen",
          "OC",
          "Kilos",
          "Metros",
          "Piezas",
          "Recibio",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado de Compras",
          email: mailTo,
          fileName: "ListadoCompras.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Compras.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    fecha,
    factura_proveedor,
    observaciones,
    numero,
    pais_origen,
    idEdit
  ) {
    setFecha(fecha);
    setFacturaProveedor(factura_proveedor);
    setObservaciones(observaciones);
    setNumeroCompra(numero);
    setPaisOrigen(pais_origen);
    setIdEdit(idEdit);
    toggleEdit();
  }

  async function PDFOC(
    fecha,
    idProveedores,
    proveedores,
    numeroOrden,
    facturaProveedor,
    kilos,
    metros,
    piezas,
    observaciones,
    observacionesOrden,
    numero,
    tipo,
    idPDFOC
  ) {
    if (tipo == "Tela") {
      await axios
        .get(`${URL_INVENTARIOS_TELAS}/compras/${idPDFOC}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allTelasCompra = response.data;
          let arrayTabla = allTelasCompra
            .sort((a, b) => (a.captura > b.captura ? 1 : -1))
            .map((a) => {
              return {
                id: a._id,
                telas: a.telas[0].codigo + " " + a.telas[0].nombre,
                idTela: a.telas[0]._id,
                unidad: a.telas[0].unidad,
                colores: a.colores[0].name,
                cantidad: a.cantidad,
                cantidadString: a.cantidad.toString(),
                precio: a.telasOC[0].precio,
                idColor: a.colores[0]._id,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let dataFinal = Object.values(arrayTabla);
          let rollos = dataFinal.length;

          let agrupado = dataFinal.reduce(function (groups, item) {
            const val = item["idTela"] + item["idColor"];
            groups[val] = groups[val] || {
              idTela: item.idTela,
              idColores: item.idColores,
              cantidad: 0,
              rollos: 0,
              cantidadString: "",
            };
            groups[val].cantidad += item.cantidad;
            groups[val].cantidadString += item.cantidadString + " / ";
            groups[val].telas = item.telas;
            groups[val].unidad = item.unidad;
            groups[val].colores = item.colores;
            groups[val].precio = item.precio;
            groups[val].rollos += 1;
            return groups;
          }, []);

          let agrupadoValues = Object.values(agrupado);

          let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
          let totalMts = mts.map((c) => parseFloat(c.cantidad));
          let TM = totalMts.reduce((t, total, index) => t + total, 0);

          let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
          let totalKG = KG.map((c) => parseFloat(c.cantidad));
          let TK = totalKG.reduce((t, total, index) => t + total, 0);

          let total_importe = 0;
          const data = agrupadoValues.map((a) => {
            total_importe = total_importe + a.precio * a.cantidad;
            return [
              a.telas,
              a.unidad,
              a.colores,
              a.rollos,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              "$" +
                new Intl.NumberFormat("en-US").format(a.precio * a.cantidad),
              a.cantidadString,
            ];
          });
          const dataPDF = Object.values(data);
          const dataPDFLimpia = dataPDF.filter(function (el) {
            return el != null;
          });
          const doc = new jsPDF("landscape");
          var img = new Image();
          img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
          doc.addImage(img, "png", 230, 35, 45, 15);
          var img2 = new Image();
          img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
          doc.addImage(img2, "png", 120, 15, 60, 25);
          doc.text(`Compra # ${numero}`, 20, 20);
          doc.setFontSize(12);
          doc.text(`Fecha ${fecha}`, 20, 25);
          doc.text(`Proveedor ${proveedores}`, 20, 30);
          doc.text(
            `Metros ${new Intl.NumberFormat("en-US").format(
              metros
            )} / Rollos ${rollos}`,
            20,
            35
          );
          doc.text(`Orden Compra: ${numeroOrden}`, 20, 40);
          doc.text(`Factura: ${facturaProveedor}`, 20, 45);
          doc.text(`Observaciones: ${observacionesOrden}`, 20, 50);
          doc.autoTable({
            head: [
              [
                "Tela",
                "Unidad",
                "Color",
                "Rollos",
                "Cantidad",
                "Precio",
                "Total",
                "Desgloce Rollos",
              ],
            ],
            body: dataPDFLimpia,
            startY: 60,
            foot: [
              [
                "",
                "",
                "Totales",
                new Intl.NumberFormat("en-US").format(rollos),
                new Intl.NumberFormat("en-US").format(metros),
                "",
                "$" + new Intl.NumberFormat("en-US").format(total_importe),
              ],
            ],
            showFoot: "lastPage",
          });
          // doc.save(`Compra-${numero}.pdf`);
          window.open(doc.output("bloburl"), "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      await axios
        .get(`${URL_COMPRAS_ACUMULADOS}/compra/${idPDFOC}`, {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        })
        .then((response) => {
          let allTelasCompra = response.data;
          let arrayTabla = allTelasCompra
            .sort((a, b) => (a.captura > b.captura ? 1 : -1))
            .map((a) => {
              return {
                id: a._id,
                habilitacion: a.habilitacion[0].nombre + " " + a.habilitacion[0].descripcion,
                idHabilitacion: a.habilitacion[0]._id,
                unidad: a.habilitacion[0].unidad,
                cantidad: a.cantidad,
                precio: a.telasOC[0].precio,
              };
            })
            .filter(function (el) {
              return el != null;
            });

          let dataFinal = Object.values(arrayTabla);
          let rollos = dataFinal.length;

          let agrupado = dataFinal.reduce(function (groups, item) {
            const val = item["idHabilitacion"];
            groups[val] = groups[val] || {
              idHabilitacion: item.idHabilitacion,
              cantidad: 0,
            };
            groups[val].cantidad += item.cantidad;
            groups[val].habilitacion = item.habilitacion;
            groups[val].unidad = item.unidad;
            groups[val].precio = item.precio;
            return groups;
          }, []);

          let agrupadoValues = Object.values(agrupado);

          let mts = agrupadoValues.filter((e) => e.unidad == "Metros");
          let totalMts = mts.map((c) => parseFloat(c.cantidad));
          let TM = totalMts.reduce((t, total, index) => t + total, 0);

          let KG = agrupadoValues.filter((e) => e.unidad == "Kilos");
          let totalKG = KG.map((c) => parseFloat(c.cantidad));
          let TK = totalKG.reduce((t, total, index) => t + total, 0);

          let PZ = agrupadoValues.filter((e) => e.unidad == "Piezas");
          let totalPZ = PZ.map((c) => parseFloat(c.cantidad));
          let TP = totalPZ.reduce((t, total, index) => t + total, 0);

          let total_importe = 0;
          const data = agrupadoValues.map((a) => {
            total_importe = total_importe + a.precio * a.cantidad;
            return [
              a.habilitacion,
              a.unidad,
              new Intl.NumberFormat("en-US").format(a.cantidad),
              "$" + new Intl.NumberFormat("en-US").format(a.precio),
              "$" +
                new Intl.NumberFormat("en-US").format(a.precio * a.cantidad),
            ];
          });
          const dataPDF = Object.values(data);
          const dataPDFLimpia = dataPDF.filter(function (el) {
            return el != null;
          });
          const doc = new jsPDF("landscape");
          var img = new Image();
          img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
          doc.addImage(img, "png", 230, 30, 45, 15);
          var img2 = new Image();
          img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
          doc.addImage(img2, "png", 120, 15, 60, 25);
          doc.text(`Compra # ${numero}`, 20, 20);
          doc.setFontSize(12);
          doc.text(`Fecha ${fecha}`, 20, 25);
          doc.text(`Proveedor ${proveedores}`, 20, 30);
          doc.text(
            `Metros ${new Intl.NumberFormat("en-US").format(
              TM
            )} / Kilos ${new Intl.NumberFormat("en-US").format(
              TK
            )} / Piezas ${new Intl.NumberFormat("en-US").format(TP)}`,
            20,
            35
          );
          doc.autoTable({
            head: [["Articulo", "Unidad", "Cantidad", "Precio", "Total"]],
            body: dataPDFLimpia,
            startY: 45,
            foot: [
              [
                "",
                "",
                "",
                "Totales",
                "$" + new Intl.NumberFormat("en-US").format(total_importe),
              ],
            ],
            showFoot: "lastPage",
          });
          // doc.save(`Compra-${numero}.pdf`);
          window.open(doc.output("bloburl"), "_blank");
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }

  function editCompras(event) {
    event.preventDefault();
    axios
      .patch(
        `${URL_COMPRAS}/${idEdit}`,
        {
          fecha,
          factura_proveedor,
          observaciones,
          pais_origen,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Compra",
            detalle: `${numero_compra} ${fecha}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  const headers = [
    { name: "Activo", field: "activo", sortable: true },
    { name: "Numero", field: "numero", sortable: true },
    { name: "Fecha", field: "fecha", sortable: false },
    { name: "Tipo", field: "tipo", sortable: false },
    { name: "OC", field: "numeroOrden", sortable: true },
    { name: "Observaciones OC", field: "observacionesOrden", sortable: true },
    { name: "Proveedor", field: "proveedores", sortable: true },
    { name: "Kilos", field: "kilos", sortable: true },
    { name: "Metros", field: "metros", sortable: true },
    { name: "Piezas", field: "piezas", sortable: true },
    { name: "Subtotal", field: "subtotal", sortable: true },
    { name: "IVA", field: "iva", sortable: true },
    { name: "Total", field: "total_costo", sortable: true },
    { name: "Recibio", field: "user", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.activo.toLowerCase().includes(search.toLowerCase()) ||
          comment.proveedores.toLowerCase().includes(search.toLowerCase()) ||
          comment.tipo.toLowerCase().includes(search.toLowerCase()) ||
          comment.user.toLowerCase().includes(search.toLowerCase()) ||
          comment.observacionesOrden
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          comment.numeroOrden.toString().includes(search) ||
          comment.kilos.toString().includes(search) ||
          comment.metros.toString().includes(search) ||
          comment.piezas.toString().includes(search) ||
          comment.total_costo.toString().includes(search) ||
          comment.subtotal.toString().includes(search) ||
          comment.iva.toString().includes(search) ||
          comment.numero.toString().includes(search)
      );
    }
    if (selectedProveedor) {
      computedComments = computedComments.filter((e) =>
        e.idProveedores.includes(selectedProveedor)
      );
    }
    if (activo) {
      computedComments = computedComments.filter((e) =>
        e.activo.includes(activo)
      );
    }

    if (selectedFechaInicio) {
      computedComments = computedComments.filter(
        (e) => e.fecha >= selectedFechaInicio
      );
    }

    if (selectedFechaFin) {
      computedComments = computedComments.filter(
        (e) => e.fecha <= selectedFechaFin
      );
    }

    if (selectedTipo) {
      computedComments = computedComments.filter((e) =>
        e.tipo.includes(selectedTipo)
      );
    }
    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field &&
      sorting.field != "kilos" &&
      sorting.field != "metros" &&
      sorting.field != "piezas" &&
      sorting.field != "numeroOrden" &&
      sorting.field != "total_costo" &&
      sorting.field != "subtotal" &&
      sorting.field != "iva" &&
      sorting.field != "numero"
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc" &&
      (sorting.field == "kilos" ||
        sorting.field == "metros" ||
        sorting.field == "piezas" ||
        sorting.field == "numeroOrden" ||
        sorting.field == "total_costo" ||
        sorting.field == "subtotal" ||
        sorting.field == "iva" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" &&
      (sorting.field == "kilos" ||
        sorting.field == "metros" ||
        sorting.field == "piezas" ||
        sorting.field == "numeroOrden" ||
        sorting.field == "total_costo" ||
        sorting.field == "subtotal" ||
        sorting.field == "iva" ||
        sorting.field == "numero")
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedProveedor,
    activo,
    selectedFechaInicio,
    selectedFechaFin,
    selectedTipo,
  ]);

  function QRGen(idCompra) {
    axios
      .get(`${URL_INVENTARIOS_TELAS}/compras/${idCompra}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allRollos = response.data;

        const doc = new jsPDF("p", "mm", [102, 80]);
        let alturaLogo = 30;
        let alturaImagen = 60;
        let alturaTexto1 = 43;
        let alturaTexto2 = 48;
        let alturaTexto3 = 53;
        let alturaTexto4 = 58;
        let alturaTexto5 = 68;
        let alturaLogo2 = 35;

        allRollos
          .sort((a, b) => (a.captura > b.captura ? 1 : -1))
          .map((a) => {
            let codigo = a._id;
            var base64 = qrcode.toDataURL(codigo);
            var img = new Image();
            img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
            doc.addImage(img, "png", 70, alturaLogo, 35, 10, null, null, 90);

            var img2 = new Image();
            img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
            doc.addImage(img2, "png", 40, alturaLogo2, 60, 25, null, null, 90);

            var img3 = new Image();
            img3.src = base64;
            doc.addImage(img3, "png", 0, alturaImagen, 40, 40);

            doc.setFontSize(11);
            doc.text(`Cod ${a.telas[0].codigo} Mod ${a.telas[0].nombre}`, alturaTexto1, 90, null, 90);
            doc.text(`Prov. ${a.compras[0].proveedores[0].nombre_comercial}`, alturaTexto2, 90, null, 90);
            doc.text(`Factura ${a.compras[0].factura_proveedor}, Color ${a.colores[0].name}`, alturaTexto3, 90, null, 90);
            doc.text(`ID ${a._id}`, alturaTexto4, 90, null, 90);

            doc.setFontSize(18);
            doc.text(
              `Cant. ${a.cantidad} ${a.telas[0].unidad}`,
              alturaTexto5,
              90,
              null,
              90
            );
            doc.addPage();
          });
        // doc.save(`QR.pdf`);
        window.open(doc.output("bloburl"), "_blank");
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function cancelarCompra(idCompra) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara la Compra!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_COMPRAS}Cancel/${idCompra}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  function cancelarCompraHab(idCompra) {
    Swal.fire({
      title: "Estas seguro?",
      text: "Se cancelara la Compra!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .get(`${URL_COMPRAS}CancelHab/${idCompra}`, {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          })
          .then(() => {
            Swal.fire("Good job!", "Enviado con exito", "success");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
              footer: `${error.response.data}`,
            });
            console.log(error);
          });
      }
    });
  }

  async function jalaFaltantes(
    fecha,
    idProveedores,
    proveedores,
    kilos,
    metros,
    piezas,
    observaciones,
    numero,
    tipo,
    idPDFOC
  ) {
    setNumeroCompra(numero);
    setIdEdit(idPDFOC);

    await axios
      .get(`${URL_COMPRAS_ACUMULADOS}/compra/${idPDFOC}`, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTelasCompra = response.data;
        let arrayTabla = allTelasCompra
          .map((a) => {
            return {
              id: a._id,
              telas: a.telas[0].nombre,
              color: a.colores[0].name,
              cantidad: a.cantidad,
              faltante: a.faltante,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setArticulosFaltante(dataFinal);

        let totalMts = dataFinal.map((c) => parseFloat(c.faltante));
        let TM = totalMts.reduce((t, total, index) => t + total, 0);
        setTotalFaltante(TM);

        toggleFaltante();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const handleChangeInputCantidad = (id, event) => {
    const newArticulosFaltante = articulosFaltante.map((i) => {
      if (id === i.id) {
        i.faltante = parseFloat(event.target.value);
      }
      return i;
    });
    setArticulosFaltante(newArticulosFaltante);
    ActualizaTotales();
  };

  function ActualizaTotales() {
    let totalMts = articulosFaltante.map((c) => parseFloat(c.faltante));
    let TM = totalMts.reduce((t, total, index) => t + total, 0);
    setTotalFaltante(TM);
  }

  async function editFaltante() {
    let totalRollos = articulosFaltante.length;

    try {
      await axios
        .patch(
          `${URL_COMPRAS}/${idEdit}`,
          {
            total_faltante: totalFaltante,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          articulosFaltante.map((a) => {
            if (a.faltante != 0) {
              axios
                .patch(
                  `${URL_COMPRAS_ACUMULADOS}/${a.id}`,
                  {
                    faltante: a.faltante,
                  },
                  {
                    headers: {
                      Authorization: `Bearer: ${localStorage.getItem(
                        "app_token"
                      )}`,
                    },
                  }
                )
                .then(() => {
                  totalRollos = totalRollos - 1;
                  if (totalRollos == 0) {
                    axios
                      .post(
                        URL_LOGS,
                        {
                          tipo: "Faltante Tela",
                          detalle: `Compra ${idEdit}`,
                          user: user.id,
                        },
                        {
                          headers: {
                            Authorization: `Bearer: ${localStorage.getItem(
                              "app_token"
                            )}`,
                          },
                        }
                      )
                      .then(() => {
                        Swal.fire("Good job!", "Creado con exito", "success");
                        setTimeout(() => {
                          window.location.reload();
                        }, 2000);
                      })
                      .catch((error) => {
                        Swal.fire({
                          icon: "error",
                          title: "Oops...",
                          text: "Something went wrong!",
                          footer: `${error.response.data}`,
                        });
                        console.log(error);
                      });
                  }
                });
            } else {
              totalRollos = totalRollos - 1;
              if (totalRollos == 0) {
                axios
                  .post(
                    URL_LOGS,
                    {
                      tipo: "Faltante Tela",
                      detalle: `Compra ${idEdit}`,
                      user: user.id,
                    },
                    {
                      headers: {
                        Authorization: `Bearer: ${localStorage.getItem(
                          "app_token"
                        )}`,
                      },
                    }
                  )
                  .then(() => {
                    Swal.fire("Good job!", "Creado con exito", "success");
                    setTimeout(() => {
                      window.location.reload();
                    }, 2000);
                  })
                  .catch((error) => {
                    Swal.fire({
                      icon: "error",
                      title: "Oops...",
                      text: "Something went wrong!",
                      footer: `${error.response.data}`,
                    });
                    console.log(error);
                  });
              }
            }
          });
        });
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    }
  }

  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_compras ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.compras_create ? (
                <>
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ComprasCreate"
                >
                  Nueva Compra
                </Button>
                <Button
                size="sm"
                type="submit"
                className="btn btn-success"
                href="/ComprasContenedorCreate"
              >
                Nueva Compra Contenedor
              </Button>
              </>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nueva Compra
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCompras"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>Enviar Listado Ordenes de Compra</h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Compras</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Row>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Inicio</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaInicio}
                required
                onChange={(e) => {
                  setSelectedFechaInicio(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label className="mr-sm-2">Fecha Fin</Label>
              <Input
                bsSize="sm"
                type="date"
                value={selectedFechaFin}
                required
                onChange={(e) => {
                  setSelectedFechaFin(e.target.value);
                }}
              />
            </Col>
            <Col md={2}>
              <Label>Proveedores</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedProveedor}
                onChange={(e) => {
                  setSelectedProveedor(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="0">Selecciona</option>
                {proveedores
                  .sort((a, b) => (a.razon_social > b.razon_social ? 1 : -1))
                  .map((a) => {
                    return <option value={a._id}>{a.razon_social}</option>;
                  })}
              </Input>
            </Col>

            <Col md={2}>
              <Label>Activo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={activo}
                required
                onChange={(e) => {
                  setActivo(e.target.value);
                  setCurrentPage(1);
                }}
              >
                <option value="">Selecciona</option>
                <option value="Si">Activos</option>
                <option value="No">Inactivos</option>
              </Input>
            </Col>
            <Col md={2}>
            
              <Label>Tipo</Label>
              <Input
                bsSize="sm"
                type="select"
                value={selectedTipo}
                onChange={(e) => {
                  setSelectedTipo(e.target.value)
                  setCurrentPage(1)
                }}
              >
                <option value="">Selecciona</option>
                <option value="Tela">Tela</option>
                <option value="Habilitacion">Habilitacion</option>
              </Input>
              </Col>
          </Row>
          <br />
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.activo}</td>
                      <td>{a.numero}</td>
                      <td>{a.fecha}</td>
                      <td>{a.tipo}</td>
                      <td>{a.numeroOrden}</td>
                      <td>{a.observacionesOrden}</td>
                      <td>{a.proveedores}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.kilos)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.metros)}</td>
                      <td>{new Intl.NumberFormat("en-US").format(a.piezas)}</td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.subtotal)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.iva)}
                      </td>
                      <td>
                        {new Intl.NumberFormat("en-US", {
                          style: "currency",
                          currency: "USD",
                          maximumFractionDigits: 2,
                        }).format(a.total_costo)}
                      </td>
                      <td>{a.user}</td>
                      <td>
                        <div>
                          <Button
                            size="sm"
                            className="btn"
                            color="danger"
                            onClick={(e) =>
                              PDFOC(
                                a.fecha,
                                a.idProveedores,
                                a.proveedores,
                                a.numeroOrden,
                                a.factura_proveedor,
                                a.kilos,
                                a.metros,
                                a.piezas,
                                a.observaciones,
                                a.observacionesOrden,
                                a.numero,
                                a.tipo,
                                a._id
                              )
                            }
                          >
                            <i class="far fa-file-pdf"></i>
                          </Button>
                          {a.activo == "Si" ? (
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.fecha,
                                  a.factura_proveedor,
                                  a.observaciones,
                                  a.numero,
                                  a.pais_origen,
                                  a._id
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                          ) : undefined}

                          {a.tipo == "Tela" && a.activo == "Si" ? (
                            <>
                              <Button
                                size="sm"
                                className="btn"
                                color="success"
                                onClick={(e) => QRGen(a._id)}
                              >
                                <i class="fas fa-qrcode"></i>
                              </Button>
                              {a.activo == "Si" && user.cancelar_compra ? (
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarCompra(a._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                              ) : undefined}
                              <Button
                                size="sm"
                                className="btn"
                                color="primary"
                                onClick={(e) =>
                                  jalaFaltantes(
                                    a.fecha,
                                    a.idProveedores,
                                    a.proveedores,
                                    a.kilos,
                                    a.metros,
                                    a.piezas,
                                    a.observaciones,
                                    a.numero,
                                    a.tipo,
                                    a._id
                                  )
                                }
                              >
                                <i class="fas fa-undo-alt "></i>
                              </Button>
                            </>
                          ) : undefined}

                          {/* Cancelacion Habilitacion */}
                          {a.activo == "Si" && a.tipo == "Habilitacion" && user.cancelar_compra ? (
                                <Button
                                  size="sm"
                                  className="btn"
                                  color="danger"
                                  onClick={(e) => cancelarCompraHab(a._id)}
                                >
                                  <i class="fas fa-ban"></i>
                                </Button>
                              ) : undefined}
                        </div>
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="lg" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>Editar Compra # {numero_compra}</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md={4}>
              <Label>Fecha</Label>
              <Input
                type="date"
                placeholder="Fecha"
                value={fecha}
                required
                onChange={(e) => {
                  setFecha(e.target.value);
                }}
              />
            </Col>

            <Col md={4}>
              <Label>Factura Proveedor</Label>
              <Input
                type="text"
                placeholder="Factura Proveedor"
                value={factura_proveedor}
                required
                onChange={(e) => {
                  setFacturaProveedor(e.target.value);
                }}
              />
            </Col>

            <Col md={4}>
              <Label>Pais Origen</Label>
              <Input
                type="text"
                placeholder="Pais Origen"
                value={pais_origen}
                required
                onChange={(e) => {
                  setPaisOrigen(e.target.value);
                }}
              />
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <Label for="exampleEmail" className="mr-sm-2">
                Observaciones
              </Label>
              <Input
                type="textarea"
                placeholder="Observaciones"
                value={observaciones}
                required
                onChange={(e) => {
                  setObservaciones(e.target.value);
                }}
              />
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editCompras}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
        <ModalHeader toggle={toggleProgreso}>
          <h4>Progreso</h4>
        </ModalHeader>
        <ModalBody>
          <h4>
            {" "}
            Este proceso puede tardar varios minutos.
            <br />
            Por favor no cierre ni refresque su navegador.
          </h4>
          <br />
          <div className="progreso">
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
            <Spinner
              style={{
                width: "50px",
                height: "50px",
                color: "#232B41",
              }}
            />
          </div>
        </ModalBody>
      </Modal>

      <Modal size="lg" isOpen={modalFaltante} toggle={toggleFaltante}>
        <ModalHeader toggle={toggleFaltante}>
          <h4>Faltante Compra # {numero_compra}</h4>
        </ModalHeader>
        <ModalBody>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <Row>
              <Col md={3}>Tela</Col>
              <Col md={3}>Color</Col>
              <Col md={3}>Cantidad Compra</Col>
              <Col md={3}>Cantidad Faltante</Col>
            </Row>
            <tbody>
              {articulosFaltante.map((a) => {
                {
                  return (
                    <Row>
                      <Col md={3}>{a.telas}</Col>
                      <Col md={3}>{a.color}</Col>
                      <Col md={3}>{(a.cantidad).toFixed(2)}</Col>
                      <Col md={3}>
                        <Input
                          bsSize="sm"
                          name="faltante"
                          type="number"
                          placeholder="Faltante"
                          value={a.faltante}
                          required
                          onChange={(event) =>
                            handleChangeInputCantidad(a.id, event)
                          }
                        />
                      </Col>
                    </Row>
                  );
                }
              })}
            </tbody>
          </Table>
          <Row>
            <Col md={6}></Col>
            <Col md={3}>
              <Label>Total</Label>
            </Col>
            <Col md={3}>
              <Label>{totalFaltante}</Label>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editFaltante}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      {loader}
    </>
  );
}

export default ListadoCompras;
