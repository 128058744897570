import React, { useState, useEffect, useContext, useMemo } from "react";
import { AuthContext } from "../../../contexts/AuthContext";
import Header from "../../../layout/Header/Header";
// import Footer from "../../layout/Footer/Footer";
import {
  Button,
  Row,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  ButtonGroup,
  Col,
  Spinner,
} from "reactstrap";
import SweetAlert from "sweetalert2-react";
import Swal from "sweetalert2";
import { jsPDF } from "jspdf";
import "jspdf-autotable";
import autoTable from "jspdf-autotable";
import ExportJsonExcel from "js-export-excel";
import encode from "nodejs-base64-encode";
import axios from "axios";
import Baja from "../../Baja";
import { TableHeader, Pagination, Search } from "../../../DataTable";
import useFullPageLoader from "../../../hooks/useFullPageLoader";
import qrcode from "qrcode-js";
import { v4 as uuidv4 } from "uuid";
import * as XLSX from "xlsx";
import { array } from "prop-types";

function ListadoArticulos() {
  const { user } = useContext(AuthContext);
  const URL_LOGS = process.env.REACT_APP_URL_LOGS;
  const URL_ARTICULOS = process.env.REACT_APP_URL_ARTICULOS;
  // const URL_MARCAS = process.env.REACT_APP_URL_MARCAS;
  const URL_COLECCIONES = process.env.REACT_APP_URL_COLECCIONES;
  const URL_SEND_MAIL_ATT = process.env.REACT_APP_URL_SEND_MAIL_ATT;
  const URL_EXPLOSION = process.env.REACT_APP_URL_EXPLOSION;
  const URL_TELAS = process.env.REACT_APP_URL_TELAS;
  const URL_HABILITACION = process.env.REACT_APP_URL_HABILITACION;
  const URL_COLORES = process.env.REACT_APP_URL_COLORES;
  const URL_TALLAS = process.env.REACT_APP_URL_TALLAS;
  const URL_CATALOGO_PROCESOS = process.env.REACT_APP_URL_CATALOGO_PROCESOS;
  const URL_COLABORADORES = process.env.REACT_APP_URL_COLABORADORES;

  const [telas, setTelas] = useState([]);
  const [habilitacion, setHabilitacion] = useState([]);
  const [is_active, setis_active] = useState("");
  const [codigo, setCodigo] = useState("");
  const [codigoCliente, setCodigoCliente] = useState("");
  const [codigoSAT, setCodigoSAT] = useState("");
  const [codigoUnidad, setCodigoUnidad] = useState("");
  const [nombre, setNombre] = useState("");
  const [descripcion, setDescripcion] = useState("");
  const [precioEtiqueta, setPrecioEtiqueta] = useState("");
  const [venta, setVenta] = useState("");
  const [observaciones, setObservaciones] = useState("NA");
  const [observacionesProduccion, setObservacionesProduccion] = useState("NA");
  // const [marcas, setMarcas] = useState([]);
  const [colecciones, setColecciones] = useState([]);
  const [selectedCodigo, setSelectedCodigo] = useState(0);
  const [selectedCodigoCliente, setSelectedCodigoCliente] = useState(0);
  const [selectedNombre, setSelectedNombre] = useState(0);
  // const [selectedMarca, setSelectedMarca] = useState(0);
  const [selectedColeccion, setSelectedColeccion] = useState(0);

  // const [selectedMarcaEdit, setSelectedMarcaEdit] = useState("");
  const [selectedColeccionEdit, setSelectedColeccionEdit] = useState("");
  const [idEditArticulo, setIdEditArticulo] = useState("");

  const [colaboradores, setColaboradores] = useState([]);
  const [selectedPatronista, setSelectedPatronista] = useState("");
  const [selectedMuestrista, setSelectedMuestrista] = useState("");
  const [selectedPloter, setSelectedPloter] = useState("");

  const [colores, setColores] = useState([]);
  const [selectedColor, setSelectedColor] = useState("");
  const [tallas, setTallas] = useState([]);
  const [selectedTalla, setSelectedTalla] = useState("");
  const [catalogoProcesos, setCatalogoProcesos] = useState([]);

  const [modalMail, setModalMail] = useState(false);
  const toggleMail = () => setModalMail(!modalMail);
  const [mailTo, setMailTo] = useState("");

  const [modalEdit, setModalEdit] = useState(false);
  const toggleEdit = () => setModalEdit(!modalEdit);

  const [modalProgreso, setModalProgreso] = useState(false);
  const toggleProgreso = () => setModalProgreso(!modalProgreso);

  const [modalExplosion, setModalExplosion] = useState(false);
  const toggleExplosion = () => setModalExplosion(!modalExplosion);
  const [explosionExistentes, setExplosionExistentes] = useState([]);
  const [codigoExplosion, setCodigoExplosion] = useState("");
  const [idExplosion, setIdExplosion] = useState("");
  const [descripcionExplosion, setDescripcionExplosion] = useState("");

  const [idColoresArticulos, setIdColoresArticulos] = useState("");
  const [modalColores, setModalColores] = useState(false);
  const toggleColores = () => setModalColores(!modalColores);
  const [arrayColores, setArrayColores] = useState([]);

  const [idTallasArticulos, setIdTallasArticulos] = useState("");
  const [modalTallas, setModalTallas] = useState(false);
  const toggleTallas = () => setModalTallas(!modalTallas);
  const [arrayTallas, setArrayTallas] = useState([]);

  const [idCatalogoProcesosArticulos, setIdCatalogoProcesosArticulos] = useState("");
  const [modalCatalogoProcesos, setModalCatalogoProcesos] = useState(false);
  const toggleCatalogoProcesos = () => setModalCatalogoProcesos(!modalCatalogoProcesos);
  const [arrayCatalogoProcesos, setArrayCatalogoProcesos] = useState([]);

  const [validaBoton, setValidaBoton] = useState(true);

  const [file, setFile] = useState();
  const [photo, setPhoto] = useState();
  const URL_FILEPOST = process.env.REACT_APP_URL_UPPROFILE;

  const [modalFoto, setModalFoto] = useState(false);
  const toggleFoto = () => setModalFoto(!modalFoto);


  const [totalCaptura, setTotalCaptura] = useState(1);
  const [cantidadAgrupado, setCantidadAgrupado] = useState([]);

  const [inputFields, setInputFields] = useState([
    {
      id: uuidv4(),
      fecha: "",
      descripcion: "",
      compradores: "",
      colaboradores: "",
      marcas: "",
      colecciones: "",
      colores: "",
      tallas: "",
      catalogoProcesos: "",
      patronista: "",
      muestrista: "",
      ploter: "",
      clientes: "",
      pedido: "",
      codigo: "",
      codigoCliente: "",
      departamentos: "",
      subDepartamentos: "",
      clases: "",
      subClases: "",
    },
  ]);

  let hoy = new Date();
  hoy.setDate(hoy.getDate() - 3650);
  const year = hoy.getFullYear();
  const years = Array.from(new Array(40), (val, index) => index + year);

  //--- Pagination
  const [comments, setComments] = useState([]);
  const [loader, showLoader, hideLoader] = useFullPageLoader();
  const [totalItems, setTotalItems] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [search, setSearch] = useState("");
  const [sorting, setSorting] = useState({ field: "", order: "" });

  const ITEMS_PER_PAGE = 50;

  const [inputFieldsTela, setInputFieldsTela] = useState([
    {
      id: uuidv4(),
      idTela: "",
      cantidad:0
    },
  ]);

  const [inputFieldsHab, setInputFieldsHab] = useState([
    {
      id: uuidv4(),
      idHab: "",
      cantidad:0
    },
  ]);

  useEffect(() => {
    axios
      .get(URL_ARTICULOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allArticulos = response.data;
        // Array para pagination
        let arrayTabla = allArticulos
          .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
          .map((a) => {
            return {
              _id: a._id,
              is_active: a.is_active,
              codigo: a.codigo,
              codigoCliente: a.codigoCliente,
              codigoSAT: a.codigoSAT,
              codigoUnidad: a.codigoUnidad,
              descripcion: a.descripcion,
              // marca: a.marcas[0].name,
              coleccion: a.colecciones[0].name,
              // idMarca: a.marcas[0]._id,
              idColeccion: a.colecciones[0]._id,
              colores: a.colores,
              tallas : a.tallas,
              catalogoProcesos: a.catalogoProcesos,
              muestrista: a.muestrista[0].nombre + " " + a.muestrista[0].apellido,
              idMuestrista: a.muestrista[0]._id,
              patronista: a.patronista[0].nombre + " " + a.patronista[0].apellido,
              idPatronista: a.patronista[0]._id,
              ploter: a.ploter[0].nombre + " " + a.ploter[0].apellido,
              idPloter: a.ploter[0]._id,
            };
          })
          .filter(function (el) {
            return el != null;
          });

        let dataFinal = Object.values(arrayTabla);
        setComments(dataFinal);
        //
      })
      .catch((err) => {
        console.log(err);
      });
    // axios
    //   .get(URL_MARCAS, {
    //     headers: {
    //       Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
    //     },
    //   })
    //   .then((res) => {
    //     const allMarcas = res.data;
    //     setMarcas(allMarcas);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });
    axios
      .get(URL_COLECCIONES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColecciones = res.data;
        setColecciones(allColecciones);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_TELAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allTelas = res.data;
        setTelas(allTelas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_HABILITACION, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allHabilitacion = res.data;
        setHabilitacion(allHabilitacion);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_COLORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allColores = response.data;
        setColores(allColores);
      })
      .catch((err) => {
        console.log(err);
      });
      axios
      .get(URL_TALLAS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allTallas = response.data;
        setTallas(allTallas);
      })
      .catch((err) => {
        console.log(err);
      });

      axios
      .get(URL_CATALOGO_PROCESOS, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((response) => {
        let allCatalogoProcesos = response.data;
        setCatalogoProcesos(allCatalogoProcesos);
      })
      .catch((err) => {
        console.log(err);
      });
  axios
      .get(URL_COLABORADORES, {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      })
      .then((res) => {
        const allColaboradores = res.data;
        setColaboradores(allColaboradores);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);


  function PDFTabla() {
    const data = comments.map((a) => {
      if (
        (selectedCodigo == 0 || selectedCodigo == a.codigo) &&
        (selectedCodigoCliente == 0 || selectedCodigoCliente == a.codigoCliente) &&
        // (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return [
          a.codigo,
          a.codigoCliente,
          a.descripcion,
          "$" + new Intl.NumberFormat("en-US").format(a.venta),
          "$" + new Intl.NumberFormat("en-US").format(a.precioEtiqueta),
          // a.marca,
          a.coleccion,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text("Modelos", 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Modelo Oficial",
          "Descripcion",
          "Venta ",
          "Precio Etiqueta",
          // "Marca",
          "Coleccion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    doc.save("Modelos.pdf");
  }

  function excel() {
    const dataExcel = comments.map((a) => {
      if (
        (selectedCodigo == 0 || selectedCodigo == a.codigo) &&
        (selectedCodigoCliente == 0 || selectedCodigoCliente == a.codigoCliente) &&
        // (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return {
          // id: a._id,
          Codigo: a.codigo,
          CodigoCliente: a.codigoCliente,
          Descripcion: a.descripcion,
          Venta: a.venta,
          PrecioEtiqueta: a.precioEtiqueta,
          // Marca: a.marca,
          Coleccion: a.coleccion,
        };
      }
    });
    const dataExportExcel = Object.values(dataExcel);
    const dataExcelLimpia = dataExportExcel.filter(function (el) {
      return el != null;
    });

    var option = {};

    option.fileName = "Modelos";

    option.datas = [
      {
        sheetData: dataExcelLimpia,
        sheetName: "Modelos",
        sheetFilter: [
          // "id",
          "Codigo",
          "CodigoCliente",
          "Descripcion",
          "Venta",
          "PrecioEtiqueta",
          // "Marca",
          "Coleccion",
        ],
        sheetHeader: [
          // "id",
          "Codigo",
          "CodigoCliente",
          "Descripcion",
          "Venta",
          "PrecioEtiqueta",
          // "Marca",
          "Coleccion",
        ],
      },
    ];

    var toExcel = new ExportJsonExcel(option);
    toExcel.saveExcel();
  }

  function enviaMail() {
    const data = comments.map((a) => {
      if (
        (selectedCodigo == 0 || selectedCodigo == a.codigo) &&
        (selectedCodigoCliente == 0 || selectedCodigoCliente == a.codigoCliente) &&
        // (selectedMarca == 0 || selectedMarca == a.idMarca) &&
        (selectedColeccion == 0 || selectedColeccion == a.idColeccion) &&
        (is_active == 0 || is_active == a.is_active)
      ) {
        return [
          a.codigo,
          a.codigoCliente,
          a.descripcion,
          "$" + new Intl.NumberFormat("en-US").format(a.venta),
          "$" + new Intl.NumberFormat("en-US").format(a.precioEtiqueta),
          // a.marca,
          a.coleccion,
        ];
      }
    });
    const dataPDF = Object.values(data);
    const dataPDFLimpia = dataPDF.filter(function (el) {
      return el != null;
    });
    const doc = new jsPDF("landscape");
    var img = new Image();
    img.src = process.env.PUBLIC_URL + "Logo-Sistemify-PDF.png";
    doc.addImage(img, "png", 230, 30, 45, 15);
    var img2 = new Image();
    img2.src = process.env.PUBLIC_URL + "logoInstitucion.png";
    doc.addImage(img2, "png", 115, 20, 55, 15);
    doc.text("Modelos", 20, 40);
    doc.autoTable({
      head: [
        [
          "Codigo",
          "Modelo Oficial",
          "Descripcion",
          "Venta ",
          "Precio Etiqueta",
          // "Marca",
          "Coleccion",
        ],
      ],
      body: dataPDFLimpia,
      startY: 45,
    });
    var att = doc.output("arraybuffer");
    var base64File = encode.encode(att, "base64");

    // Envia el Mail
    axios
      .post(
        URL_SEND_MAIL_ATT,
        {
          subject: "Listado Modelos",
          email: mailTo,
          fileName: "ListadoModelos.pdf",
          att: base64File,
          text: `<table border="0" cellpadding="0" cellspacing="0" width="100%">
         <th><img width="${process.env.REACT_APP_TAMANO_LOGO_MAIL}" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}logoInstitucion.png" /></th>
         <tr align="center" style="font-size:30px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>${process.env.REACT_APP_NOMBRE_INSTITUCION}</tr>
         <tbody>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Hola,</tr>
         <tr style="font-size:20px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px;font-weight:bold"><br>Adjunto encontraras el listado de Modelos.</tr>
         </tbody>
         <tr style="font-size:16px;font-family:Helvetica,Arial,sans-serif;color:#333333;padding-top:10px"><br>Favor de no responder este correo, para cualquier duda favor de comunicarse al ${process.env.REACT_APP_TELEFONO_INSTITUCION}</tr>
         <tr align="right"><br>Powered by <a href="https://sistemify.com"> <img width="20%" align="center" src="${process.env.REACT_APP_LINK_SISTEMA}Logo-Sistemify-PDF.png" /> </a> </tr>
         </table>`,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      )
      .then(() => {
        toggleMail();
        Swal.fire("Good job!", "Enviado con exito", "success");
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
  }

  function jalaInfo(
    codigo,
    codigoCliente,
    codigoSAT,
    codigoUnidad,
    descripcion,
    venta,
    precioEtiqueta,
    observaciones,
    observacionesProduccion,
    // idMarca,
    idColeccion,
    idEdit,
    idMuestrista,
    idPatronista,
    idPloter
  ) {
    setCodigo(codigo);
    setCodigoCliente(codigoCliente);
    setCodigoSAT(codigoSAT);
    setCodigoUnidad(codigoUnidad);
    setDescripcion(descripcion);
    setVenta(venta);
    setPrecioEtiqueta(precioEtiqueta);
    setObservaciones(observaciones);
    setObservacionesProduccion(observacionesProduccion);
    // setSelectedMarcaEdit(idMarca);
    setSelectedColeccionEdit(idColeccion);
    setIdEditArticulo(idEdit);
    setSelectedMuestrista(idMuestrista)
    setSelectedPatronista(idPatronista)
    setSelectedPloter(idPloter)
    toggleEdit();
  }

  function editArticulos(event) {
    event.preventDefault();
    // const URL_ARTICULOS_EDIT = `${process.env.REACT_APP_URL_ARTICULOS}/${idEditArticulo}`;
    if (file == null) {
        axios.patch(
          `${URL_ARTICULOS}/${idEditArticulo}`,
          {
            codigo,
            codigoCliente,
            codigoSAT,
            codigoUnidad,
            descripcion,
            venta,
            precioEtiqueta,
            observaciones,
            observacionesProduccion,
            // marcas: selectedMarcaEdit,
            colecciones: selectedColeccionEdit, 
            patronista: selectedPatronista,
            muestrista: selectedMuestrista,
            ploter: selectedPloter,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
      .then(() => {
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Articulo",
            detalle: `${codigo}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        );

        toggleEdit();
        Swal.fire("Good job!", "Actualizado con exito", "success");
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!",
          footer: `${error.response.data}`,
        });
        console.log(error);
      });
    } else{
        axios.patch(
          `${URL_ARTICULOS}/${idEditArticulo}`,
          {
            codigo,
            codigoCliente,
            codigoSAT,
            codigoUnidad,
            descripcion,
            venta,
            precioEtiqueta,
            observaciones,
            observacionesProduccion,
            // marcas: selectedMarcaEdit,
            colecciones: selectedColeccionEdit,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
        .then(() => {
          const formData = new FormData();
          formData.append("file", file);
          formData.append("articulo", idEditArticulo);
  
          axios
            .post(URL_FILEPOST, formData, {
              headers: {
                Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
              },
            })
            .then(() => {
              axios.post(
                URL_LOGS,
                {
                  tipo: "Editar Articulo",
                  detalle: `${codigo}`,
                  user: user.id,
                },
                {
                  headers: {
                    Authorization: `Bearer: ${localStorage.getItem(
                      "app_token"
                    )}`,
                  },
                }
              );
  
              toggleEdit();
              Swal.fire("Good job!", "Actualizado con exito", "success");
              setTimeout(() => {
                window.location.reload();
              }, 1000);
            })
            .catch((error) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                footer: `${error.response.data}`,
              });
              console.log(error);
            });
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
          });
          console.log(error);
        });
    }
  }

  const headers = [
    { name: "Activo", field: "is_active", sortable: true },
    { name: "Codigo", field: "codigo", sortable: true },
    { name: "Modelo Oficial", field: "codigoCliente", sortable: true },
    { name: "Descripcion", field: "descripcion", sortable: true },
    // { name: "Marca", field: "marca", sortable: true },
    { name: "Coleccion", field: "coleccion", sortable: true },
    { name: "Acciones", field: "acciones", sortable: false },
  ];

  const commentsData = useMemo(() => {
    let computedComments = comments;

    if (search) {
      computedComments = computedComments.filter(
        (comment) =>
          comment.codigo.toLowerCase().includes(search.toLowerCase()) ||
          comment.codigoCliente.toLowerCase().includes(search.toLowerCase()) ||
          comment.descripcion.toLowerCase().includes(search.toLowerCase()) ||
          // comment.marca.toLowerCase().includes(search.toLowerCase()) ||
          comment.coleccion.toLowerCase().includes(search.toLowerCase())
      );
    }
    if (selectedCodigo) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    if (selectedCodigoCliente) {
      computedComments = computedComments.filter((e) =>
        e._id.includes(selectedCodigo)
      );
    }
    // if (selectedMarca) {
    //   computedComments = computedComments.filter((e) =>
    //     e.idMarca.includes(selectedMarca)
    //   );
    // }
    if (selectedColeccion) {
      computedComments = computedComments.filter((e) =>
        e.idColeccion.includes(selectedColeccion)
      );
    }
    if (is_active) {
      computedComments = computedComments.filter((e) =>
        e.is_active.includes(is_active)
      );
    }

    setTotalItems(computedComments.length);

    //Sorting comments
    if (
      sorting.field
    ) {
      const reversed = sorting.order === "asc" ? 1 : -1;
      computedComments = computedComments.sort(
        (a, b) => reversed * a[sorting.field].localeCompare(b[sorting.field])
      );
    }

    if (
      sorting.field &&
      sorting.order === "asc"  
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) > parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    if (
      sorting.field &&
      sorting.order === "desc" 
    ) {
      computedComments = computedComments.sort((a, b) =>
        parseFloat(a[sorting.field]) < parseFloat(b[sorting.field]) ? 1 : -1
      );
    }

    //Current Page slice
    return computedComments.slice(
      (currentPage - 1) * ITEMS_PER_PAGE,
      (currentPage - 1) * ITEMS_PER_PAGE + ITEMS_PER_PAGE
    );
  }, [
    comments,
    currentPage,
    search,
    sorting,
    selectedCodigo,
    selectedCodigoCliente,
    // selectedMarca,
    selectedColeccion,
    is_active,
  ]);

  function explosion(id, codigo, descripcion){
    setIdExplosion(id)
    setCodigoExplosion(codigo)
    setDescripcionExplosion(descripcion)

    axios
    .get(`${URL_EXPLOSION}Articulo/${id}`, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((res) => {
      const allExplosionExistentes = res.data;
      setExplosionExistentes(allExplosionExistentes);
    })
    .catch((err) => {
      console.log(err);
    });

    toggleExplosion()

  }

  const handleRemoveFieldsTelas = (id) => {
    const values = [...inputFieldsTela];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsTela(values);
  };

  const handleRemoveFieldsHab = (id) => {
    const values = [...inputFieldsHab];
    values.splice(
      values.findIndex((value) => value.id === id),
      1
    );
    setInputFieldsHab(values);
  };

  const handleAddFieldsTelas = () => {
    setInputFieldsTela([
      ...inputFieldsTela,
      {
        id: uuidv4(),
        idTela: "",
        cantidad:0
      },
    ]);
  };

  const handleAddFieldsHab = () => {
    setInputFieldsHab([
      ...inputFieldsHab,
      {
        id: uuidv4(),
        idHab: "",
        cantidad:0
      },
    ]);
  };

  const handleChangeInputTela = (id, event) => {
    const newInputFieldsTela = inputFieldsTela.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsTela(newInputFieldsTela);
  };

  const handleChangeInputHab = (id, event) => {
    const newInputFieldsHab = inputFieldsHab.map((i) => {
      if (id === i.id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setInputFieldsHab(newInputFieldsHab);
  };

  const handleChangeInputExistente = (id, event) => {
    const newExplosionExistentes = explosionExistentes.map((i) => {
      if (id === i._id) {
        i[event.target.name] = event.target.value;
      }
      return i;
    });
    setExplosionExistentes(newExplosionExistentes);
  };

function saveExplosion(){
  setValidaBoton(false);
  toggleProgreso();

let totalExplosion = inputFieldsHab.length + inputFieldsTela.length + explosionExistentes.length

  inputFieldsTela.map((a)=>{
    if(a.idTela!="" & a.cantidad > 0){
    axios.post(
      URL_EXPLOSION,
      {
        articulos: idExplosion,
        telas: a.idTela,
        cantidad: a.cantidad,
        tipo: "Tela"
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Explosion",
            detalle: `${idExplosion} ${a.idTela}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  }else{
    totalExplosion = totalExplosion -1
  }
  })

  inputFieldsHab.map((a)=>{
    if(a.idHab!="" & a.cantidad > 0){
    axios.post(
      URL_EXPLOSION,
      {
        articulos: idExplosion,
        habilitacion: a.idHab,
        cantidad: a.cantidad,
        tipo: "Habilitacion"
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Agregar Explosion",
            detalle: `${idExplosion} ${a.idHab}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  }else{
    totalExplosion = totalExplosion -1
  }
  })

  explosionExistentes.map((a)=>{
    axios.patch(
      `${URL_EXPLOSION}/${a._id}`,
      {
        cantidad: a.cantidad,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    ).then(()=>{
      totalExplosion = totalExplosion -1
      if(totalExplosion == 0 ){
        axios.post(
          URL_LOGS,
          {
            tipo: "Editar Explosion",
            detalle: `${idExplosion}`,
            user: user.id,
          },
          {
            headers: {
              Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
            },
          }
        )
          .then(()=>{
            Swal.fire("Good job!", "Creado con exito", `success`);
            setTimeout(() => {
              window.location.reload();
            }, 1000);
          })
      }
    })
  })

}

function coloresInfo(idColores, codigo, Descripcion, coloresInfo) {
  let ids = coloresInfo.map(function (e) {
    return e._id;
  });
  let coloresDany = Object.values(ids);

  setIdColoresArticulos(idColores);
  setCodigo(codigo);
  setDescripcion(descripcion);
  setArrayColores(coloresDany);
  toggleColores();
}

function coloresSeleccionados(checked, idColor) {
  if (checked) {
    arrayColores.push(idColor);
    console.log(arrayColores);
  } else {
    const index = arrayColores.indexOf(idColor);
    if (index > -1) {
      arrayColores.splice(index, 1);
      console.log(arrayColores);
    }
  }
}

function guardarColores() {
  const URL_ARTICULOS_COLORES = `${process.env.REACT_APP_URL_ARTICULOS}/${idColoresArticulos}`;
  axios
    .patch(
      URL_ARTICULOS_COLORES,
      {
        colores: arrayColores,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios.post(
        URL_LOGS,
        {
          tipo: "Colores Articulo",
          detalle: `${codigo}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function tallasInfo(idTallas, codigo, descripcion, tallasInfo) {
  let ids = tallasInfo.map(function (e) {
    return e._id;
  });
  let tallasDany = Object.values(ids);
  setIdTallasArticulos(idTallas);
  setCodigo(codigo);
  setDescripcion(descripcion);
  setArrayTallas(tallasDany);
  toggleTallas();
}

function tallasSeleccionados(checked, idTalla) {
  if (checked) {
    arrayTallas.push(idTalla);
    console.log(arrayTallas);
  } else {
    const index = arrayTallas.indexOf(idTalla);
    if (index > -1) {
      arrayTallas.splice(index, 1);
      console.log(arrayTallas);
    }
  }
}

function guardarTallas() {
  const URL_ARTICULOS_TALLAS = `${process.env.REACT_APP_URL_ARTICULOS}/${idTallasArticulos}`;
  axios
    .patch(
      URL_ARTICULOS_TALLAS,
      {
        tallas: arrayTallas,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios.post(
        URL_LOGS,
        {
          tipo: "Tallas Articulo",
          detalle: `${codigo}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}


function catalogoProcesosInfo(idCatalogoProcesos, codigo, descripcion, catalogoProcesosInfo) {
  let ids = catalogoProcesosInfo.map(function (e) {
    return e._id;
  });
  let catalogoProcesosDany = Object.values(ids);

  setIdCatalogoProcesosArticulos(idCatalogoProcesos);
  setCodigo(codigo);
  setDescripcion(descripcion);
  setArrayCatalogoProcesos(catalogoProcesosDany);
  toggleCatalogoProcesos();
}

function catalogoProcesosSeleccionados(checked, idCatalogoProceso) {
  if (checked) {
    arrayCatalogoProcesos.push(idCatalogoProceso);
    console.log(arrayCatalogoProcesos);
  } else {
    const index = arrayCatalogoProcesos.indexOf(idCatalogoProceso);
    if (index > -1) {
      arrayCatalogoProcesos.splice(index, 1);
      console.log(arrayCatalogoProcesos);
    }
  }
}

function guardarCatalogoProcesos() {
  const URL_ARTICULOS_CATALOGO_PROCESOS = `${process.env.REACT_APP_URL_ARTICULOS}/${idCatalogoProcesosArticulos}`;
  axios
    .patch(
      URL_ARTICULOS_CATALOGO_PROCESOS,
      {
        catalogoProcesos: arrayCatalogoProcesos,
      },
      {
        headers: {
          Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
        },
      }
    )
    .then(() => {
      axios.post(
        URL_LOGS,
        {
          tipo: "CatalogoProcesos Articulo",
          detalle: `${codigo}`,
          user: user.id,
        },
        {
          headers: {
            Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
          },
        }
      );
      Swal.fire("Good job!", "Actualizado con exito", "success");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    })
    .catch((error) => {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        footer: `${error.response.data}`,
      });
      console.log(error);
    });
}

function jalaFoto(
  idEdit
) {
  setPhoto("")
  const URL_GET_MEDIA = `${process.env.REACT_APP_URL_GETMEDIA}/${idEdit}`;
  axios
    .get(URL_GET_MEDIA, {
      headers: {
        Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
      },
    })
    .then((data) => {
      if (data.data.version) {
        setPhoto(data.data.version);
      }
    })
    .catch((err) => console.log(err));


  setIdEditArticulo(idEdit);
  toggleFoto();
}

// function importarArchivo(event) {
//   const target = event.target;
//   const value = target.type === "checkbox" ? target.checked : target.value;
//   const name = target.name;
//   let hojas = [];

//   if (name === "file") {
//     let reader = new FileReader();
//     reader.readAsArrayBuffer(target.files[0]);
//     reader.onloadend = (e) => {
//       var data = new Uint8Array(e.target.result);
//       var workbook = XLSX.read(data, { type: "array" });

//       workbook.SheetNames.forEach(function (sheetName) {
//         // Here is your object
//         var XL_row_object = XLSX.utils.sheet_to_json(
//           workbook.Sheets[sheetName]
//         );
//         hojas.push({
//           data: XL_row_object,
//           sheetName,
//         });
//       });

//       let A1 = hojas[0].data;

//       let TC = totalCaptura;
//       A1.map((a) => {
//         TC = TC + 1;
//         setTotalCaptura(TC);
//         inputFields.push({
//           id: uuidv4(),
//           fecha: "2023-07-05",
//           descripcion: a.descripcion,
//           compradores: "6488e1ecf6e88800149b9e88",
//           colaboradores: "62672cdfc3a7a03b8fec2bec",
//           marcas: a.marcas,
//           colecciones: a.colecciones,
//           colores: a.colores,
//           tallas: a.tallas,
//           catalogoProcesos: "6363fb596cdd150aab0461cd",
//           patronista: "63925f542134b10016b86764",
//           muestrista: "63925f542134b10016b86764",
//           ploter: "63925f542134b10016b86764",
//           clientes: a.clientes,
//           pedido: "NA",
//           codigo: a.codigo,
//           codigoCliente: a.codigoCliente,
//           departamentos: a.departamentos,
//           subDepartamentos: a.subDepartamentos,
//           clases: a.clases,
//           subClases: a.subClases,
//         });
//       });

//       let agrupado = inputFields.reduce(function (groups, item) {
//         const val = item["codigo"];
//         groups[val] = groups[val] || {
//           codigo: item.codigo,
//           colores: [],
//           tallas: [],
//         };
//         groups[val].colores.push(item.colores);
//         groups[val].tallas.push(item.tallas);
//         groups[val].fecha = item.fecha;
//         groups[val].descripcion = item.descripcion;
//         groups[val].compradores = item.compradores;
//         groups[val].colaboradores = item.colaboradores;
//         groups[val].marcas = item.marcas;
//         groups[val].colecciones = item.colecciones;
//         groups[val].catalogoProcesos = item.catalogoProcesos;
//         groups[val].patronista = item.patronista;
//         groups[val].muestrista = item.muestrista;
//         groups[val].ploter = item.ploter;
//         groups[val].clientes = item.clientes;
//         groups[val].pedido = item.pedido;
//         groups[val].codigo = item.codigo;
//         groups[val].codigoCliente = item.codigoCliente;
//         groups[val].departamentos = item.departamentos;
//         groups[val].subDepartamentos = item.subDepartamentos;
//         groups[val].clases = item.clases;
//         groups[val].subClases = item.subClases;
//         return groups;
//       }, []);

//       let agrupadoValues = Object.values(agrupado);

//       let agrupadoColores = agrupadoValues.map((a)=>{
//         if(a.descripcion != ""){
//         return{
//             descripcion: a.descripcion,
//             compradores: a.compradores,
//             colaboradores: a.colaboradores,
//             marcas: a.marcas,
//             colecciones: a.colecciones,
//             colores: a.colores.filter((item,index)=>{
//               return a.colores.indexOf(item) === index;
//             }),
//             tallas: a.tallas.filter((item,index)=>{
//               return a.tallas.indexOf(item) === index;
//             }),
//             catalogoProcesos: a.catalogoProcesos,
//             patronista: a.patronista,
//             muestrista: a.muestrista,
//             ploter: a.ploter,
//             clientes: a.clientes,
//             codigo: a.codigo,
//             codigoCliente: a.codigoCliente,
//             departamentos: a.departamentos,
//             subDepartamentos: a.subDepartamentos,
//             clases: a.clases,
//             subClases: a.subClases,
//         }
//       }
//       })
//       .filter(function (el) {
//         return el != null;
//       });

//       console.log("hola2", agrupadoColores);

//       agrupadoColores.map(async(a)=>{
//         if(a.fecha != ""){
//         await axios
//         .post(
//           URL_ARTICULOS,
//           {
//             fecha: "2023-07-11",
//             descripcion: a.descripcion,
//             compradores: a.compradores,
//             colaboradores: a.colaboradores,
//             marcas: a.marcas,
//             colecciones: a.colecciones,
//             colores: a.colores,
//             tallas: a.tallas,
//             catalogoProcesos: a.catalogoProcesos,
//             patronista: a.patronista,
//             muestrista: a.muestrista,
//             ploter: a.ploter,
//             clientes: a.clientes,
//             pedido: "NA",
//             codigo: a.codigo.toString(),
//             codigoCliente: a.codigoCliente.toString(),
//             departamentos: a.departamentos,
//             subDepartamentos: a.subDepartamentos,
//             clases: a.clases,
//             subClases: a.subClases,
//           },
//           {
//             headers: {
//               Authorization: `Bearer: ${localStorage.getItem("app_token")}`,
//             },
//           }
//         )
//         .then(() => {
//           Swal.fire("Good job!", "Creado con exito", "success");
//         })
//         .catch((error) => {
//           Swal.fire({
//             icon: "error",
//             title: "Oops...",
//             text: "Something went wrong!",
//             footer: `${error.response.data}`,
//           });
//           console.log(error);
//           setValidaBoton(true);
//         })
//         }


//       })

//     };
//   }
// }


  return (
    <>
      <Header />
      <br />
      <br />
      {user.menu_articulos ? (
        <div className="card col-12">
          <Row>
            <Col md={8}>
              {user.articulos_create ? (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  href="/ArticulosCreateSolo"
                >
                  Nuevo Modelo
                </Button>
              ) : (
                <Button
                  size="sm"
                  type="submit"
                  className="btn btn-success"
                  disabled
                >
                  Nuevo Modelo
                </Button>
              )}
              <Button
                size="sm"
                href="/MenuCatalogos"
                className="btn btn-danger"
                id="botonListado"
              >
                Regresar
              </Button>
              {/* <Col>
                    <Input
                      required
                      type="file"
                      name="file"
                      id="file"
                      onChange={(event) =>
                        importarArchivo(event)
                      }
                    />
                    </Col> */}
            </Col>
            <Col sm={4}>
              <ButtonGroup id="logoutBoton">
                <Button
                  size="sm"
                  className="btn"
                  color="danger"
                  onClick={PDFTabla}
                >
                  PDF <i class="far fa-file-pdf"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  onClick={toggleMail}
                  color="info"
                >
                  eMail <i class="fas fa-at"></i>
                </Button>
                <Button
                  size="sm"
                  className="btn"
                  color="primary"
                  onClick={excel}
                >
                  Excel <i class="far fa-file-excel"></i>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>
          <Modal size="sm" isOpen={modalMail} toggle={toggleMail}>
            <ModalHeader toggle={toggleMail}>
              <h4>
                Enviar Modelos
              </h4>
            </ModalHeader>
            <ModalBody>
              <Label className="mr-sm-2">Email</Label>
              <Input
                className="col-sm-12"
                type="text"
                value={mailTo}
                required
                onChange={(e) => {
                  setMailTo(e.target.value);
                }}
              />
            </ModalBody>
            <ModalFooter>
              <Button size="sm" color="success" onClick={enviaMail}>
                Enviar
              </Button>
            </ModalFooter>
          </Modal>
          <h3 align="center">Modelos</h3>
          <div className="row">
            <div className="col-md-8">
              <Pagination
                total={totalItems}
                itemsPerPage={ITEMS_PER_PAGE}
                currentPage={currentPage}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
            <div className="col-md-4 d-flex flex-row-reverse">
              <Search
                onSearch={(value) => {
                  setSearch(value);
                  setCurrentPage(1);
                }}
              />
            </div>
          </div>
          <Table size="sm" striped borderless className="table-responsive-xl">
            <TableHeader
              headers={headers}
              onSorting={(field, order) => setSorting({ field, order })}
            />
            <tr>
              <td style={{ paddingTop: "0px" }}></td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigo}
                  onChange={(e) => {
                    setSelectedCodigo(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.codigo > b.codigo ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigo}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedCodigoCliente}
                  onChange={(e) => {
                    setSelectedCodigoCliente(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {comments
                    .sort((a, b) => (a.codigoCliente > b.codigoCliente ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.codigoCliente}</option>;
                    })}
                </Input>
              </td>
              <td style={{ paddingTop: "0px" }}></td>
              {/* <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedMarca}
                  onChange={(e) => {
                    setSelectedMarca(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td> */}
              <td style={{ paddingTop: "0px" }}>
                <Input
                  bsSize="sm"
                  type="select"
                  value={selectedColeccion}
                  onChange={(e) => {
                    setSelectedColeccion(e.target.value);
                    setCurrentPage(1);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </td>
              <td></td>
            </tr>
            <tbody>
              {commentsData.map((a) => {
                {
                  return (
                    <tr>
                      <td>{a.is_active}</td>
                      <td>{a.codigo}</td>
                      <td>{a.codigoCliente}</td>
                      <td>{a.descripcion}</td>
                      {/* <td>{a.marca} </td> */}
                      <td>{a.coleccion} </td>
                      <td>
                        {user.articulos_create ? (
                          <div>
                              <Button
                              color="success"
                              id="Colores"
                              size="sm"
                              onClick={(e) =>
                                coloresInfo(
                                  a._id,
                                  a.codigo,
                                  a.descripcion,
                                  a.colores
                                )
                              }
                            >
                            <i class="fas fa-palette"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Tallas"
                              size="sm"
                              onClick={(e) =>
                                tallasInfo(
                                  a._id,
                                  a.codigo,
                                  a.descripcion,
                                  a.tallas
                                )
                              }
                            >
                            <i class="fas fa-ruler-horizontal"></i>
                            </Button>
                            <Button
                              color="success"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                explosion(
                                  a._id,
                                  a.codigo,
                                  a.descripcion
                                )
                              }
                            >
                              <i class="fas fa-tag"></i>
                            </Button>
                            <Button
                              color="danger"
                              id="CatalogoProcesos"
                              size="sm"
                              onClick={(e) =>
                                catalogoProcesosInfo(
                                  a._id,
                                  a.codigo,
                                  a.descripcion,
                                  a.catalogoProcesos
                                )
                              }
                            >
                            <i class="fas fa-tshirt"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaInfo(
                                  a.codigo,
                                  a.codigoCliente,
                                  a.codigoSAT,
                                  a.codigoUnidad,
                                  a.descripcion,
                                  a.venta,
                                  a.precioEtiqueta,
                                  a.observaciones,
                                  a.observacionesProduccion,
                                  // a.idMarca,
                                  a.idColeccion,
                                  a._id,
                                  a.idMuestrista,
                                  a.idPatronista,
                                  a.idPloter
                                )
                              }
                            >
                              <i class="far fa-edit"></i>
                            </Button>
                            <Button
                              color="info"
                              id="Editar"
                              size="sm"
                              onClick={(e) =>
                                jalaFoto(
                                  a._id
                                )
                              }
                            >
                              <i class="fas fa-camera"></i>
                            </Button>
                            <Baja
                              idStatus={a._id}
                              is_active={a.is_active}
                              URL_BAJA={process.env.REACT_APP_URL_ARTICULOS}
                            />
                          </div>
                        ) : (
                          <div>
                            <Button color="info" id="Editar" size="sm" disabled>
                              <i class="far fa-edit"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                }
              })}
            </tbody>
          </Table>
          <div className="col-md-6">
            <Pagination
              total={totalItems}
              itemsPerPage={ITEMS_PER_PAGE}
              currentPage={currentPage}
              onPageChange={(page) => setCurrentPage(page)}
            />
          </div>
        </div>
      ) : undefined}
      <br />

      <Modal size="xl" isOpen={modalEdit} toggle={toggleEdit}>
        <ModalHeader toggle={toggleEdit}>
          <h4>
            Editar Modelo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
              <Col md={3}>
                <Label>Codigo</Label>
                <Input
                  type="text"
                  placeholder="Codigo"
                  value={codigo}
                  required
                  onChange={(e) => {
                    setCodigo(e.target.value);
                  }}
                />
              </Col>

              <Col md={3}>
                <Label>Descripcion</Label>
                <Input
                  type="text"
                  placeholder="Descripcion"
                  value={descripcion}
                  required
                  onChange={(e) => {
                    setDescripcion(e.target.value);
                  }}
                />
              </Col>
              
              {/* <Col md={3}>
                <Label className="mr-sm-2">Marca</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedMarcaEdit}
                  required
                  onChange={(e) => {
                    setSelectedMarcaEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {marcas
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col> */}
              
              <Col md={3}>
                <Label className="mr-sm-2">Coleccion</Label>
                <Input
                  className="col-sm-12"
                  type="select"
                  value={selectedColeccionEdit}
                  required
                  onChange={(e) => {
                    setSelectedColeccionEdit(e.target.value);
                  }}
                >
                  <option value="0">Selecciona</option>
                  {colecciones
                    .sort((a, b) => (a.name > b.name ? 1 : -1))
                    .map((a) => {
                      return <option value={a._id}>{a.name}</option>;
                    })}
                </Input>
              </Col>
              
            
              <Col md={3}>
                <Label>Modelo Oficial</Label>
                <Input
                  type="text"
                  placeholder="CodigoCliente"
                  value={codigoCliente}
                  required
                  onChange={(e) => {
                    setCodigoCliente(e.target.value);
                  }}
                />
              </Col>
              </Row>
              <Row>
              <Col md={3}>
                <Label>Patronista</Label>
                <Input
                  type="select"
                  value={selectedPatronista}
                  onChange={(e) => {
                    setSelectedPatronista(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Muestrista</Label>
                <Input
                  type="select"
                  value={selectedMuestrista}
                  onChange={(e) => {
                    setSelectedMuestrista(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>

              <Col md={3}>
                <Label>Ploter</Label>
                <Input
                  type="select"
                  value={selectedPloter}
                  onChange={(e) => {
                    setSelectedPloter(e.target.value);
                  }}
                >
                  <option value="">Selecciona</option>
                  {colaboradores
                    .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                    .map((a) => {
                      return (
                        <option value={a._id}>
                          {a.nombre} {a.apellido}
                        </option>
                      );
                    })}
                </Input>
              </Col>
            </Row>
            <Row>

            <Col md={3}>
                <Label>Codigo SAT</Label>
                <Input
                  type="select"
                  placeholder="CodigoSAT"
                  value={codigoSAT}
                  required
                  onChange={(e) => {
                    setCodigoSAT(e.target.value);
                  }}
                  >
                    <option value="">Selecciona</option>
                    <option value="NA">NA</option>
                    <option value="53101604">53101604</option>
                    <option value="53102002">53102002</option>
                    <option value="53101504">53101504</option>
                    <option value="53101804">53101804</option>
                    <option value="53101704">53101704</option>
                    <option value="53103100">53103100</option>
                    <option value="53102800">53102800</option>
                    <option value="1010101">1010101</option>
                  </Input>
              </Col>

              <Col md={3}>
                <Label>Codigo Unidad</Label>
                <Input
                  type="text"
                  placeholder="CodigoUnidad"
                  value={codigoUnidad}
                  required
                  onChange={(e) => {
                    setCodigoUnidad(e.target.value);
                  }}
                />
              </Col>
            </Row>
              <Row>
              <Col md={3}>
              <Label>
                Foto
              </Label>
              <Input
                type="file"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                }}
              />
            </Col>
              </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={editArticulos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalExplosion} toggle={toggleExplosion}>
        <ModalHeader toggle={toggleExplosion}>
          <h4>
            Explosion Modelo {codigoExplosion} / {descripcionExplosion}
          </h4>
        </ModalHeader>
        <ModalBody>

          <Row>
            <Col md={6}>
            <Label>Tela</Label>
            </Col>
            <Col md={3}>
            <Label>Cantidad</Label>
            </Col>
          </Row>
          {explosionExistentes.map((a)=>(
            a.tipo=="Tela"?(
            <div key={a._id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="telas"
                        type="select"
                        value={a.telas[0]._id}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputExistente(a._id, event)}}
                        >
                        </Input>
                      </Col>
              </Row>
            </div>):undefined
          ))}
             {inputFieldsTela.map((a)=>(
            <div key={a.id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="idTela"
                        type="select"
                        value={a.idTela}
                        required
                        onChange={(event)=>{handleChangeInputTela(a.id, event)}}
                      >
                        <option value="">Selecciona</option>
                        {telas
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.codigo} / {a.nombre}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputTela(a.id, event)}}
                        >
                        </Input>
                      </Col>
                      <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsTela.length === 1}
                        onClick={() => handleRemoveFieldsTelas(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsTelas}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
              </Row>
            </div>
          ))}
    <br/>
        <Row>
            <Col md={6}>
            <Label>Habilitacion</Label>
            </Col>
            <Col md={3}>
            <Label>Cantidad</Label>
            </Col>
          </Row>
          {explosionExistentes.map((a)=>(
            a.tipo=="Habilitacion"?(
            <div key={a._id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="habilitacion"
                        type="select"
                        value={a.habilitacion[0]._id}
                        required
                        disabled
                      >
                        <option value="">Selecciona</option>
                        {habilitacion
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                 {a.nombre} {a.descripcion}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputExistente(a._id, event)}}
                        >
                        </Input>
                      </Col>
              </Row>
            </div>):undefined
          ))}
          {inputFieldsHab.map((a)=>(
            <div key={a.id}>
              <Row>
              <Col md={6}>
                      <Input
                        bsSize="sm"
                        name="idHab"
                        type="select"
                        value={a.idHab}
                        required
                        onChange={(event)=>{handleChangeInputHab(a.id, event)}}
                      >
                        <option value="">Selecciona</option>
                        {habilitacion
                          .sort((a, b) => (a.nombre > b.nombre ? 1 : -1))
                          .map((a) => {
                            return (
                              <option value={a._id}>
                                {a.nombre} {a.descripcion}
                              </option>
                            );
                          })}
                      </Input>
                    </Col>
                    <Col md={3}>
                        <Input
                         bsSize="sm"
                          name="cantidad"
                          type="number"
                          value={a.cantidad}
                          onChange={(event)=>{handleChangeInputHab(a.id, event)}}
                        >
                        </Input>
                      </Col>
                      <Col>
                      <Button
                        size="sm"
                        className="btn"
                        color="danger"
                        disabled={inputFieldsHab.length === 1}
                        onClick={() => handleRemoveFieldsHab(a.id)}
                        tabindex="-1"
                      >
                        <i class="fas fa-minus"></i>
                      </Button>
                      <Button 
                      size="sm"
                      className="btn"
                      color="info"
                      onClick={handleAddFieldsHab}
                      tabindex="-1">
                      <i class="fas fa-plus"></i>
                      </Button>
                    </Col>
              </Row>
            </div>
          ))}
      </ModalBody>
        <ModalFooter>
        {validaBoton ? (
              <Button type="submit" className="btn btn-success" onClick={saveExplosion}>
                Guardar
              </Button>
            ) : (
              <Button type="submit" className="btn btn-success" disabled>
                Guardar
              </Button>
            )}
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalColores} toggle={toggleColores}>
        <ModalHeader toggle={toggleColores}>
          <h4>
            Colores del Articulo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {colores
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayColores.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          coloresSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarColores}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalTallas} toggle={toggleTallas}>
        <ModalHeader toggle={toggleTallas}>
          <h4>
            Tallas del Articulo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {tallas
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayTallas.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          tallasSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarTallas}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="lg" isOpen={modalCatalogoProcesos} toggle={toggleCatalogoProcesos}>
        <ModalHeader toggle={toggleCatalogoProcesos}>
          <h4>
            Procesos del Articulo {codigo} / {descripcion}
          </h4>
        </ModalHeader>
        <ModalBody>
          {catalogoProcesos
            .sort((a, b) => (a.name > b.name ? 1 : -1))
            .map((a, index) => {
              if (!arrayCatalogoProcesos.includes(a._id)) {
                return (
                  <table>
                    <td>
                      <Input
                        type="checkbox"
                        key={index}
                        onChange={(e) => {
                          catalogoProcesosSeleccionados(e.currentTarget.checked, a._id);
                        }}
                        className="subMenu"
                      />
                      <h5 className="subMenuTitulo">{a.name}</h5>
                    </td>
                  </table>
                );
              } else {
                return <h5 className="subMenuTitulo">{a.name}</h5>;
              }
            })}
        </ModalBody>
        <ModalFooter>
          <Button color="success" onClick={guardarCatalogoProcesos}>
            Guardar
          </Button>
        </ModalFooter>
      </Modal>

      <Modal size="sm" isOpen={modalFoto} toggle={toggleFoto}>
        <ModalHeader toggle={toggleFoto}>
          <h4>
            Ficha Tecnica
          </h4>
        </ModalHeader>
        <ModalBody>
        <Row>
            {photo ? (
              <h6 style={{ textAlign: "center" }}>
                <img
                  // loading="lazy"
                  src={`${process.env.REACT_APP_URL_FOTO_CLOUDINARY}/image/upload/v${photo}/saparnasaArticulos/${idEditArticulo}`}
                  alt=""
                />
              </h6>
            ) : <h4>No se ha subido una Ficha Tecnica....</h4>}
          </Row>
        </ModalBody>
      </Modal>

      <Modal size="sm" isOpen={modalProgreso} toggle={toggleProgreso}>
            <ModalHeader toggle={toggleProgreso}>
              <h4>Progreso</h4>
            </ModalHeader>
            <ModalBody>
             <h4> Este proceso puede tardar varios minutos.
              <br />
              Por favor no cierre ni refresque su navegador.
              </h4>
              <br />
              <div className="progreso">
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
                  <Spinner
                    style={{
                      width: "50px",
                      height: "50px",
                      color: "#232B41",
                    }}
                  />
              </div>
            </ModalBody>
          </Modal>

      {loader}
    </>
  );
}

export default ListadoArticulos;
